import * as React from "react";
import useScript from "./useOneTapLogin/useScripts";
import { googleMapsUrl } from "./constants";

export const usePlacesAutocomplete = (query?: string) => {
  const [suggestions, setSuggestions] = React.useState<any>();

  const script = useScript(googleMapsUrl);

  const [getingReady, setGettingReady] = React.useState<boolean>(false);

  // center place for search results
  const [center, setCenter] = React.useState<{ lat: number; lng: number }>({
    lat: 34,
    lng: -5,
  }); // default morocco

  React.useEffect(() => {
    if (script === "ready") {
      setGettingReady(true);
    } else {
      return;
    }
  }, [script]);

  React.useEffect(() => {
    if (getingReady) {
      const Geocoder = new window.google.maps.Geocoder();
      Geocoder.geocode(
        {
          address: "morocco",
        },
        (result: any, status: any) => {
          if (status === window.google.maps.GeocoderStatus.OK) {
            const lat = result[0].geometry.location.lat();
            const lng = result[0].geometry.location.lng();

            setCenter({ lat, lng });
          }
        }
      );
    }
  }, [getingReady]);

  React.useEffect(() => {
    if (query) {
      const map = new window.google.maps.Map(document.getElementById("map"), {
        center,
        zoom: 12,
        mapTypeId: "roadmap",
      });
      const location = new window.google.maps.LatLng(center.lat, center.lng);
      const request = {
        location,
        radius: "500",
        query,
      };

      const service = new window.google.maps.places.PlacesService(map);

      service.textSearch(request, (places: any, status: any) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setSuggestions(places);
        }
      });
    }
  }, [query]);

  return {
    getingReady,
    suggestions,
  };
};
