import * as React from "react";
import { Link, Route, Routes } from "react-router-dom";

import { Breadcrumbs } from "@ui";

export const ROUTE_BASE = "components";

export const SpaceExtensionsDash: React.FC<any> = ({}) => {

  return (
    <div style={{ width: "inherit" }}>
      <Breadcrumbs />
      <Routes>
        <Route
          path="extensions"
          element={
            <h2>
              extensions installed in admin plateform enable in space here
            </h2>
          }
        />
        <Route
          path="/"
          element={
            <>
              <h2>Space users</h2>

              <h4>Demarrer</h4>
              <ul style={{ listStyle: "none" }}>
                <li style={{ cursor: "pointer", marginBottom: "5px" }}>
                  <Link to="">
                    <i className="fas fa-envelope-open" style={{ margin: "5px" }} />
                    @invitations
                  </Link>
                </li>
                <li style={{ cursor: "pointer", marginBottom: "5px" }}>
                  <Link to="">
                    <i className="fas fa-user-tie" style={{ margin: "5px" }} />
                    @Staff
                  </Link>
                </li>
                <li style={{ cursor: "pointer", marginBottom: "5px" }}>
                  <Link to="spaces">
                    <i className="fas fa-users" style={{ margin: "5px" }} />
                    @collaborators
                  </Link>
                </li>
                <li style={{ cursor: "pointer", marginBottom: "5px" }}>
                  <Link to="extensions">
                    <i
                      className="far fa-handshake"
                      style={{ margin: "5px" }}
                    />
                    @affiliates
                  </Link>
                </li>
                <li style={{ cursor: "pointer", marginBottom: "5px" }}>
                  <Link to={"rules"}>
                    <i className="far fa-bell" style={{ margin: "5px" }} />
                    @Rules
                  </Link>
                </li>
                <li style={{ cursor: "pointer", marginBottom: "5px" }}>
                  <Link to={"notifications"}>
                    <i className="far fa-bell" style={{ margin: "5px" }} />
                    @Notifications
                  </Link>
                </li>
              </ul>
            </>
          }
        />
      </Routes>
    </div>
  );
};

export default SpaceExtensionsDash;
