import React, { useState } from "react";
import ProductChangeTracker from "./ProductChangeTracker";


interface NewProductsManagerProps {
  onSave: (productId: string | number, changes: Partial<Product>) => void;
  loading: boolean
}

const NewProductsManager: React.FC<NewProductsManagerProps> = ({ onSave, loading }) => {
  const [newProducts, setNewProducts] = useState<Product[]>([]);
  const [changesTracker, setChangesTracker] = useState<Record<string | number, Partial<Product>>>({});

  const handleAddProduct = () => {
    const newProduct: Product = {
      _id: Date.now(),
      title: "Product Title",
      barcode: "Scan Product Code",
      stock: { quantity: 10000, unit: "unit" },
      categories: [],
      price: { value: 20, currency: "MAD" },
    };
    setNewProducts((prev) => [...prev, newProduct]);
  };

  const handleChange = (
    productId: string | number,
    field: keyof Product | keyof Stock | keyof Price,
    value: any
  ) => {
    setNewProducts((prev) =>
      prev.map((product) =>
        product._id === productId ? { ...product, [field]: value } : product
      )
    );

    setChangesTracker((prev) => ({
      ...prev,
      [productId]: { ...prev[productId], [field]: value },
    }));
  };

  const isTitleModifiedAndNotEmpty = (productId: string | number) => {
    const modifiedTitle = changesTracker[productId]?.title;

    return (
      modifiedTitle !== undefined &&
      modifiedTitle.trim() !== ""
    );
  };

  const handleSave = (productId: string | number, changes: Partial<Product>) => {
    if (!isTitleModifiedAndNotEmpty(productId)) {
      alert("Title cannot be empty or unchanged.");
      return;
    }

    onSave(productId, changes);

    // Clear changes after saving
    setChangesTracker((prev) => {
      const { [productId]: _, ...rest } = prev;
      return rest;
    });
  };
  if(loading) return <div>working on it....</div>

  console.log(changesTracker)
  return (
    <div>
      <button onClick={handleAddProduct}>Add New Product</button>
      <ProductChangeTracker
        products={newProducts}
        onChange={handleChange}
        changesTracker={changesTracker}
        onSave={handleSave}
      />
    </div>
  );
};

export default NewProductsManager;