import { History } from 'history';
import {createRootReducer, IttyniState, rootSaga} from './store'
import { Store, createStore, applyMiddleware, Middleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
// We'll be using Redux Devtools. We can use the `composeWithDevTools()`
// directive so we can pass our middleware along with it
import { composeWithDevTools } from 'redux-devtools-extension'

// 
export default function configureStore(history: History, initialState: IttyniState): any {
    const sagaMiddleware = createSagaMiddleware();

    const middlewares: Middleware[] = [
        routerMiddleware(history),
        sagaMiddleware,
        logger as Middleware // Explicitly typecast logger as Middleware
    ];

    const composeEnhancers = composeWithDevTools({});

    const store = createStore(
        createRootReducer(history),
        initialState,
        composeEnhancers(applyMiddleware(...middlewares))
    );

    sagaMiddleware.run(rootSaga);

    return store;
}