import { FormGroupContainer, LabelStyled, StyledTextArea } from "../../../../";

/**
 * Select Component for iTTyni.com
 * @param 
 * @returns 
 */
export const TextArea: React.FC<any> = (props) => (
    <StyledTextArea
      {...props}
    />
  )

export const TextAreaFormGroup: React.FC<any> = (props) => (
  <FormGroupContainer>
    <LabelStyled>{props.title}</LabelStyled>
    <TextArea {...props} border />
  </FormGroupContainer>
)