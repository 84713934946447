import { AnyAction } from 'redux';
import { UserActions } from './userActions';

export const UserReducer = (state: any = {}, action: AnyAction) => {
  switch (action.type) {
    case UserActions.WRITE_USER_DETAILS:
      return { ...state, ...action.payload };
    case UserActions.WRITE_USER_LOGOUT:
      
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default UserReducer;