import { gql } from "@apollo/client";
import { COMPONENT_FIELDS } from "./fragments";

export const PUBLIC_USER_WRITE_USERBYPASSWORD = gql`
  mutation user_write_userByPassword($newUser: _UserSignUpInput) {
    public_user_write_signupByEmail(userInput: $newUser) {
      token
    }
  }
`;
export const PUBLIC_USER_WRITE_USERBYOAuth = gql`
  mutation public_user_write_signUpWithGoogle($user: _GGInput) {
    public_user_write_signUpWithGoogle(ggInput: $user) {
      token
    }
  }
`;
// Read controllers
export const PUBLIC_USER_READ_AUTH_WITH_EMAIL = gql`
  query public_user_read_loginWithEmail($email: String, $password: String) {
    public_user_read_loginWithEmail(email: $email, password: $password) {
      token
    }
  }
`;
export const PUBLIC_USER_READ_AUTHBYTOKEN = gql`
  query public_user_read_authByToken($token: String) {
    public_user_read_authByToken(token: $token) {
      _id
      picture
      username
      email
    }
  }
`;
export const ADMIN_USER_READ_ENABLED_EXTENSIONS = gql`
  query admin_user_read_enabledExtensions {
    admin_user_read_enabledExtensions {
      _id
    }
  }
`;
export const PUBLIC_USER_WRITE_INSTALL_EXTENSION = gql`
  mutation public_user_write_installExtension($_id: ID!) {
    public_user_write_installExtension(_id: $_id)
  }
`;
export const READ_INSTALLED_ADMIN_COMPONENTS = gql`
  query admin_user_read_installed_admin_components {
    admin_user_read_installed_admin_components {
      ...ComponentFields
    }
  }
  ${COMPONENT_FIELDS}
`;
export const READ_INSTALLED_ADMIN_MODULES = gql`
  query admin_user_read_installedAdminModules {
    admin_user_read_installedAdminModules {
      ...ComponentFields
    }
  }
  ${COMPONENT_FIELDS}
`;
export const READ_COMPONENTS = gql`
  query admin_user_read_components {
    admin_user_read_components {
      installed {
        name
        _id
        icon
      }
      notInstalled {
        name
        _id
        icon
      }
    }
  }
`;
export const INSTALL_COMPONENT = gql`
  mutation public_user_write_installExtension($_id: ID!) {
    public_user_write_installExtension(_id: $_id)
  }
`;
export const INSTALLED_SPACES = gql`
  query admin_user_read_installedSpaces {
    admin_user_read_installedSpaces {
      _id
      name
    }
  }
`;
export const READ_SPACE_COMPONENTS = gql`
  query space_user_read_spaceComponents {
    space_user_read_spaceComponents {
      ...ComponentFields
    }
  }
  ${COMPONENT_FIELDS}
`;
export const READ_SPACE_MODULES = gql`
  query space_user_read_spaceComponentsModule {
    space_user_read_spaceComponentsModule {
      ...ComponentFields
    }
  }
  ${COMPONENT_FIELDS}
`;