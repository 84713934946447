import { gql } from '@apollo/client';

export const GroupFragment = gql`
  fragment GroupFragment on Group {
    attributes {
      transform
      fill
      stroke
    }
    paths {
      d
    }
  }
`;
export const IcoFragment = gql`
  fragment IcoFragment on Ico {
    viewBox
    groups {
      ...GroupFragment
    }
    paths {
      d
    }
  }
  ${GroupFragment}
`;


export const COMPONENT_FIELDS = gql`
  fragment ComponentFields on Component {
    _id name icon status version route_base
  }
`;
