import * as React from "react";
import { PUBLIC_USER_WRITE_INSTALL_EXTENSION } from "../controller/userController";
import { useMutation } from "@apollo/client";
import { Link, Navigate, Route, Routes } from "react-router-dom";
import { AdminEnableExtension } from "./AdminEnableExtension";
import { Breadcrumbs } from "@ui";
import { AdminUserSpaces } from "./AdminUserSpaces";

export const ROUTE_BASE = "user";

export const AdminUserDashboard: React.FC<any> = ({}) => {
  const [installExtension, installExtResponse] = useMutation(
    PUBLIC_USER_WRITE_INSTALL_EXTENSION
  );
  return (
    <div style={{ width: "inherit" }}>
      <Breadcrumbs />
      <Routes>
        <Route path="#" element={<span />} />
        <Route path="extensions" element={<AdminEnableExtension />} />
        <Route path="spaces" element={<AdminUserSpaces />} />
        <Route
          path="/"
          element={
            <>
              <h2
                onClick={() =>
                  installExtension({
                    variables: { _id: "62d025208200cf2608688044" },
                  })
                }
              >
                user page
              </h2>

              <h4>Demarrer</h4>
              <ul style={{ listStyle: "none" }}>
                <li style={{ cursor: "pointer", marginBottom: "5px" }}>
                  <i className="fas fa-user-edit" style={{ margin: "5px" }} />
                  Update Your Profile
                </li>
                <li style={{ cursor: "pointer", marginBottom: "5px" }}>
                  <i className="fas fa-cogs" style={{ margin: "5px" }} />
                  Change Current Settings
                </li>
                <li style={{ cursor: "pointer", marginBottom: "5px" }}>
                  <Link to="./spaces">
                    <i className="fas fa-link" style={{ margin: "5px" }} />
                    @my Spaces
                  </Link>
                </li>
                <li style={{ cursor: "pointer", marginBottom: "5px" }}>
                  <Link to="./extensions">
                    <i
                      className="fas fa-user-shield"
                      style={{ margin: "5px" }}
                    />
                    @my Extensions
                  </Link>
                </li>
                <li style={{ cursor: "pointer", marginBottom: "5px" }}>
                  <Link to={"./notifications"}>
                    <i className="far fa-bell" style={{ margin: "5px" }} />
                    Notifications
                  </Link>
                </li>
              </ul>
            </>
          }
        />
      </Routes>
    </div>
  );
};

export default AdminUserDashboard;
