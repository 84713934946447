import * as React from 'react'
import { Input } from '../../../ui-ittyni'
import { useTranslation } from 'react-i18next'
import { SpaceFinder } from './spaceFinder'

export const SpaceIdentification: React.FC<any> = ({ spaceInformation, setSpaceInformation }) => {
    const [SpaceFinderModal, openSpaceFinder] = React.useState<boolean>(false);
    const [spaceSelected, setSpaceSelected] = React.useState<any>()
    const { t } = useTranslation();
    const handleChange = ({ target }: any) =>
        setSpaceSelected({ ...spaceSelected, account : { [target.name]: target.value } });
    const clearData = () => {
        setSpaceSelected(undefined)
    }

    React.useEffect(() => {
        setSpaceInformation({...spaceInformation, space: spaceSelected})
    }, [spaceSelected])

    return (
        <fieldset style={{ display: "flex", flexDirection: 'column', marginBottom: "15px" }} >
            <legend>IDENTIFICATION</legend>
            <div>
                <label>{t("space.identification.name")} :</label>
                <Input
                    border="1px solid lightgrey" width="50%" name="name" onChange={handleChange}
                    value={spaceSelected?.account?.name || ''}
                />
                {spaceSelected && <button onClick={() => clearData()} ><i className='fas fa-times-circle' /></button>}
                {!spaceSelected && <button onClick={() => openSpaceFinder(!SpaceFinderModal)} ><i className='fas fa-search' /></button>}
            </div>
            {spaceSelected?.photo && <div><img src={spaceSelected?.photo} height={200} width={200} /></div>}
            
            {SpaceFinderModal &&
                <SpaceFinder
                    showModal={true}
                    setShowModal={openSpaceFinder}
                    onSelectSpace={(space: any) => {
                        setSpaceSelected({
                        photo: space?.photos[0]?.getUrl(),
                        account: {
                            name: space.name, rating: space.rating,
                            user_ratings_total: space.user_ratings_total
                        },
                        space_id: space.place_id,
                        geometry: {
                            location: {
                                latitude: space.geometry.location.lat(),
                                longitude: space.geometry.location.lng()
                            }
                        }
                })}}
                />}
        </fieldset >
    )
}