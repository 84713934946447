import * as React from 'react';
import { Link, Navigate, Routes, Route, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {UserWebSignup} from './UserWebSignup';
import {UserWebLogin} from './UserWebLogin';
import {UserWebPasswordReset} from './UserWebPasswordReset';

export const ROUTE_BASE = 'user'
const WebUserDashboard: React.FC<any> = () => {
  const { isAuth } = useSelector((state: any) => state.user);
  const location = useLocation();

  // Redirect if the user is authenticated
  if (isAuth) {
    return <Navigate to="/" />;
  }

  return (
    <div>
      <div>
        <Routes>
          <Route path="login" element={<UserWebLogin />} />
          <Route path="signup" element={<UserWebSignup />} />
          <Route path="reset_password" element={<UserWebPasswordReset />} />
          <Route path="/" element={<Navigate to="login" replace />} />
        </Routes>
      </div>
      
    </div>
  );
};

export default WebUserDashboard;
