import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const ActionButton = styled("button")<{ primary?: boolean }>`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  color: white;
  background-color: ${(props) => (props.primary ? '#ff4b4b' : '#ffd1d1')};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
`;

const Actions = () => (
  <>
    <ActionButton primary><Link to="../cart">Buy now</Link></ActionButton>
    <ActionButton>Add to cart</ActionButton>
  </>
);

export default Actions;
