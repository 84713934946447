import React, { useState, useEffect } from 'react';

interface EditInPlaceTextAreaProps {
  initialData: string;
  saveChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  name: string;
  placeholder?: string;
}

export const EditInPlaceTextArea: React.FC<EditInPlaceTextAreaProps> = ({
  initialData,
  saveChange,
  name,
  placeholder = "Click to edit",
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [textData, setTextData] = useState<string>(initialData);

  // Update the text data when initialData changes
  useEffect(() => {
    setTextData(initialData);
  }, [initialData]);

  // Function to handle blur event and save the changes
  const handleSave = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setIsEditing(false);
    saveChange(e); // Trigger saveChange callback
  };

  return (
    <>
      {isEditing ? (
        <textarea
          name={name}
          onBlur={handleSave}
          onChange={(e) => setTextData(e.target.value)}
          value={textData}
          style={{ width: '100%', fontSize: '16px', padding: '8px',height: "60px" }}
          placeholder={placeholder}
          autoFocus
        />
      ) : (
        <span
          onClick={() => setIsEditing(true)}
          style={{ fontSize: '16px', cursor: 'pointer', display: 'block', padding: '8px', border: '1px solid #ddd', borderRadius: '5px' }}
        >
          {textData || placeholder}
        </span>
      )}
    </>
  );
};