import { styled, IThemeInterface } from '../..';

export const StyledButton = styled('div')<{
  theme: IThemeInterface;
  width?: string;
}>`
  width: ${({ width }) => (width ? width : 'inherit')};
  min-height: 40px;
  text-align: center;
  background-color: rgb(255, 255, 255);
  display: inline-flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.54);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  padding: 0px;
  border-radius: 2px;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  justify-content: center;
  outline: -webkit-focus-ring-color auto 1px;
  cursor: pointer;
`;
