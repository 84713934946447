import { IcoRenderer } from '@ui/src/components/ico_renderer/src/IcoRenderer';
import styled from 'styled-components';
// import {IcoRenderer} from ''

export const Card = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
`;

export const Icon = styled(IcoRenderer)`
  width: 50px;
  height: 50px;
  margin-right: 16px;
`;

export const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Title = styled('h3')`
  font-size: 16px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  text-align: left;
`;

export const ModuleInfoLink = styled('a')`
  font-size: 14px;
  color: #007bff;
  cursor: pointer;
  text-decoration: none;
  margin-top: 4px;

  &:hover {
    text-decoration: underline;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
`;

export const InstallButton = styled('button')`
  background-color: #007bff;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-right: 8px;

  &:hover {
    background-color: #0056b3;
  }
`;
export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 16px;
  padding: 16px;

  @media (max-width: 600px) {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
  }
`;
