import * as React from 'react';
import { Link, Navigate, Routes, Route, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';


export const ROUTE_BASE = 'space'

const WebSpaceDashboard: React.FC<any> = () => {


  return (
    <div>
      this is a public space      
    </div>
  );
};

export default WebSpaceDashboard;
