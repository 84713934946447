import { EditText } from "@ui";
import React, { useState } from "react";
import styled from "styled-components";
import ProductOptionsModal from "./ProductOptionsModal";
import ProductOptionsDisplayer from "./ProductOptionsDisplayer";
import { PMActions } from "@extensions/ittyni_products/store/pmActions";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";

const ColorSection = styled.div`
  padding: 10px;
  text-align: left;
`;

const ColorTitle = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
`;

const ColorOptions = styled.div`
  display: flex;
`;

const ColorOption = styled.div<{ color: string }>`
  width: 30px;
  height: 30px;
  background-color: ${(props) => props.color};
  border-radius: 3px;
  margin: 0 5px;
  cursor: pointer;
`;

const ProductOptions: React.FC<any> = ({price}) =>{
    const [options, setOptions] = React.useState([])
    const [showModal, setShowModal] = React.useState(false);
    const [searchParams] = useSearchParams();
    const _id = searchParams.get('_id');

    // store
    const dispatch = useDispatch();

    React.useEffect(() => {
      if(!!options.length){
        dispatch({type: PMActions.PRODUCT_UPDATE_DRAFT_UPDATE, payload: {_id, options}})
      }
    }, [options]);
    return(
        <>
         <ColorSection>
        <ColorTitle onClick={()=>setShowModal(!showModal)}>Click For Options</ColorTitle>
        <ProductOptionsDisplayer options={options} onOptionChange={(c:string, v: any)=>console.log(c,v)}/>
        <ProductOptionsModal setProductOptions={setOptions} setShowAddOptions={()=>setShowModal(!showModal)}
          showAddOptions={showModal}/>
      </ColorSection>
        </>
    )
}

export default ProductOptions;