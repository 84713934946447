import { AnyAction, Reducer } from "redux";

const categoryReducers : Reducer<any> = (state={}, action : AnyAction) =>{
    switch (action.type) {
        default:
            return{...state};
    }
    
}

export default categoryReducers;