import { Bubble } from "../../../ui-ittyni";
import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import FileUpload from "./FileUpload";
import { Modal } from "../../../ui-ittyni/src/components/modal";

export const ProductInfos: React.FC<any> = ({ product, setProduct }) => {
  const [category, chooseCategory] = React.useState<any>();
  const [uploadedFilesData, setFilesUploadedData] = React.useState<any[]>([]);
  const handleChange = ({ target }: any) =>
    setProduct({ ...product, [target.name]: target.value });
  const { hash } = useLocation();
  React.useEffect(() => {
    if (uploadedFilesData) {
      setProduct({ ...product, images: uploadedFilesData });
    }
  }, [uploadedFilesData]);

  React.useEffect(() => {
    if (category) {
      let tmp = product;
      if (tmp.categories) {
        tmp.categories = tmp.categories.filter(
          (ctg: any) => ctg._id !== category._id
        );
        tmp.categories.push(category);
      } else tmp.categories = [category];
      setProduct(tmp);
    }
  }, [category]);
  return (
    <div>
      <div style={{ margin: "10px 0" }}>
        <label>Product Name:</label>
        <input
          type="text"
          name="name"
          value={product?.name || ""}
          onChange={handleChange}
          style={{ width: "60%" }}
        />
      </div>
      <div style={{ margin: "10px 0", display: "flex" }}>
        <label>Description:</label>
        <textarea
          name="description"
          value={product?.description || ""}
          onChange={handleChange}
          style={{ width: "60%" }}
        />
      </div>
      <div style={{ margin: "10px 0" }}>
        <label>Price:</label>
        <input
          type="number"
          name="price"
          value={product?.price || ""}
          onChange={handleChange}
          style={{ width: "60%" }}
        />
      </div>
      <div
        style={{
          margin: "10px 0",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <label>Category:</label>

        {product?.categories?.map((category: any) => (
          <Bubble>
            {category.name}
            <i
              className="fas fa-times-circle"
              style={{
                position: "absolute",
                right: "-3px",
                top: "-12px",
                color: "red",
                zIndex: 9,
              }}
              onClick={() =>
                setProduct({
                  ...product,
                  categories: product.categories.filter(
                    (ctg: any) => ctg._id !== category._id
                  ),
                })
              }
            />
          </Bubble>
        ))}
        <Bubble>
          <Link to="#listCategory">
            <span>add category</span>
            <i className="fas fa-plus" style={{ cursor: "pointer" }} />
          </Link>
        </Bubble>
      </div>
      <div style={{ margin: "10px 0" }}>
        <label>Stock Quantity:</label>
        <input
          type="number"
          name="stockQuantity"
          value={product?.stockQuantity || ""}
          onChange={handleChange}
          style={{ width: "60%" }}
        />
      </div>
      <div style={{ margin: "10px 0" }}>
        <label>Barcode:</label>
        <input
          type="string"
          name="barcode"
          value={product?.barcode || ""}
          onChange={handleChange}
          style={{ width: "60%" }}
        />
      </div>
      <div
        style={{
          minHeight: "300px",
          position: "relative",
          padding: "10px 0",
        }}
      >
        <FileUpload
          uploadedFilesData={uploadedFilesData}
          setFilesUploadedData={setFilesUploadedData}
        />
      </div>
      {/* {(hash === "#listCategory" || hash === "#addNewCategory") && (
        <Modal
          header={<h5>Choose Product Category</h5>}
          openModal={() => history.push("./addProduct")}
        >
          <div style={{ display: "flex", marginBottom: "15px" }}>
            <Bubble>
              <Link to="#listCategory">List Category</Link>
            </Bubble>
            <Bubble>
              <Link to="#addNewCategory">Add Category</Link>
            </Bubble>
          </div>
          {hash === "#listCategory" && (
            <CategoryManager
              category={category}
              chooseCategory={chooseCategory}
            />
          )}
          {hash === "#addNewCategory" && <AddCategory />}
        </Modal>
      )} */}
    </div>
  );
};
