import React from 'react';
import styled from 'styled-components';

const ShipToSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
`;

const ShipToText = styled.div`
  font-size: 14px;
`;

const ShipToCountry = styled.div`
  font-size: 14px;
  font-weight: bold;
`;

const ShipTo = () => (
  <ShipToSection>
    <ShipToText>Ship to</ShipToText>
    <ShipToCountry>Morocco</ShipToCountry>
  </ShipToSection>
);

export default ShipTo;