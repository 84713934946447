import * as React from "react";
import styled from "styled-components";
import ProductDescription from "./ProductDescription";
import Actions from "./ProductActions";

// Define the props interface
interface ProductInfoProps {
  storeName: string;
  storeSells: string;
  avatarUrl: string;
  productDescription: string;
  likes: string;
  dislikes: string;
}

const StoreInfo = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
  padding: 0 20px;
`;

const AvatarContainer = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #e1e1e1;
  margin-right: 10px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`;

const StoreDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;

  .storeName {
    font-size: 18px;
    font-weight: bold;
  }

  .storeSells {
    font-size: 16px;
    font-weight: bold;
    color: #007bff;
  }

  .followers {
    font-size: 14px;
    color: #666;
    margin-top: 5px;
  }
`;



const ProductDescriptionContainer = styled.div`
  margin-top: 20px;
  padding: 0 20px;
`;

const ProductInfoContainer = styled.div`
  padding: 20px;
`;

// Subcomponents
const Avatar: React.FC<{ avatarUrl: string }> = ({ avatarUrl }) => (
  <AvatarContainer>
    <img src={avatarUrl} alt="Store Avatar" />
  </AvatarContainer>
);

const StoreDetails: React.FC<{
  storeName: string;
  storeSells: string;
  followers: number;
}> = ({ storeName, storeSells, followers }) => (
  <StoreDetailsContainer>
    <div className="storeName">{storeName}</div>
    <div className="storeSells">{storeSells}</div>
    <div className="followers">{followers} followers</div>
  </StoreDetailsContainer>
);


const ProductInfo: React.FC<ProductInfoProps> = ({
  storeName,
  storeSells,
  avatarUrl,
  productDescription
}) => {
  return (
    <ProductInfoContainer>
      <StoreInfo>
        <Avatar avatarUrl={avatarUrl} />
        <StoreDetails
          storeName={storeName}
          storeSells={storeSells}
          followers={10}
        />
        <Actions />
      </StoreInfo>
      <ProductDescription description={productDescription} />
    </ProductInfoContainer>
  );
};

export default ProductInfo;
