import * as React from 'react';
import AdminModulesList from '@core/ittyni_modules/admin/adminModuleList';
import { Breadcrumbs } from '@ui';
import { Routes, Link, Route } from 'react-router-dom';
import { AdminSpaceRegister } from './AdminSpaceRegister';

export const ROUTE_BASE = "space"


const AdminSpaceDashboard: React.FC<any> = () => {
    

  return(
   <div style={{ width: "inherit" }}>
      <Breadcrumbs />
      <Routes>
        <Route path="addSpace" element={<AdminSpaceRegister />} />
        <Route path="deleteSpace" element={<AdminModulesList />} />

        <Route
          path="/"
          element={
            <>
              <h2 onClick={() => console.log("clicked")}>user name</h2>

              <h4>Demarrer</h4>
              <ul style={{ listStyle: "none" }}>
                <li>
                  <Link to={`/admin/khmammed/${ROUTE_BASE}/addSpace`}>
                    <i className="fas fa-file" />
                    Ajouter un Espace
                  </Link>
                </li>
      
                <li>
                  <Link to={`/admin/khmammed/${ROUTE_BASE}/create`}>
                    <i className="fas fa-list" aria-hidden="true" />
                    Supprimer un Espace
                  </Link>
                </li>
              </ul>
              <h5>Professionelles</h5>
              <ul style={{ listStyle: "none" }}>
                <li>
                  <i className="fas fa-file" />
                  Nouveau Ordonnance d'Analyses Medicales
                </li>
                <li>
                  <i className="fas fa-folder" />
                  Ajouter Extension
                </li>
                <li>
                  <i className="fas fa-folder" />
                  Activer un Compte
                </li>
              </ul>
            </>
          }
        />
      </Routes>
    </div> 
  )
}


export default AdminSpaceDashboard;