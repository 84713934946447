import * as React from 'react';
import { ENABLE_SPACE_EXTENSION, INSTALLED_COMPONENTS } from '../controller/spaceController';
import { useMutation, useQuery } from '@apollo/client';
import ModuleGrid from '@core/ittyni_modules/components/GridCard/ModuleGrid';


export const SpaceExtensions: React.FC<any> = ()=> {
    const [extension, setExtension] = React.useState<any>();
    const installedExtensions = useQuery(INSTALLED_COMPONENTS);
    const [enableExtension, enableExtensionData] = useMutation(ENABLE_SPACE_EXTENSION)

    React.useEffect(()=>{
        const ext: any = installedExtensions.data?.space_space_read_installedComponents
        if(ext) {
            setExtension({installed: ext?.installed?.map((module: any) => ({
          _id: module._id,
          icon: module.icon,
          title: module.name,
          description: module.description,
          buttonLabel: "Enable",
          onClick: (_id: string) => enableExtension({variables:{_id}}),
        })),
        enabled: ext?.enabled?.map((module: any) => ({
          _id: module._id,
          icon: module.icon,
          title: module.name,
          description: module.description,
          buttonLabel: "remove",
          onClick: (_id: string) => console.log(`../updateExtension/${_id}`),
        }))
    })
        }
    },[installedExtensions.data]);
    React.useEffect(()=>{installedExtensions.refetch()},[enableExtensionData.data])
    return (
        <div>
            <h2>Space enabled Extensions</h2>
            <ModuleGrid modules={extension?.enabled} />
            <h2>Space installed Extensions</h2>
            <ModuleGrid modules={extension?.installed} />
        </div>
    )
}