import * as React from 'react';

import { Breadcrumbs } from '@ui';
import { Routes, Link, Route } from 'react-router-dom';
import { SpaceExtensions } from './SpaceExtensions';
import { SpaceCategories } from './SpaceCategories';


export const ROUTE_BASE = "space"


const SpaceDashboard: React.FC<any> = () => {
    

  return(
   <div style={{ width: "inherit" }}>
      <Breadcrumbs />
      <Routes>
        <Route path="profile" element={<h2>Space Profile</h2>} />
        <Route path="history" element={<h2>Space Histories</h2>} />
         <Route path="extensions" element={<SpaceExtensions />} />
         <Route path="categories" element={<SpaceCategories />} />
          <Route path="settings" element={<h2>Space Settings</h2>} />
        <Route
          path="/"
          element={
            <>
              <h2 onClick={() => console.log("clicked")}>Space Manager</h2>

              <h4>Demarrer</h4>
              <ul style={{ listStyle: "none" }}>
                <li style={{ cursor: "pointer", marginBottom: "5px" }}>
                  <Link to={`profile`}>
                    <i className="fas fa-building" style={{ margin: "5px" }} />
                     @Profile
                  </Link>
                </li>
      
                <li style={{ cursor: "pointer", marginBottom: "5px" }}>
                  <Link to={`history`}>
                    <i className="fas fa-history" style={{ margin: "5px" }}/>
                     @History
                  </Link>
                </li>
                
                <li style={{ cursor: "pointer", marginBottom: "5px" }}>
                  <Link to={'extensions'}>
                    <i className="fas fa-puzzle-piece" style={{ margin: "5px" }} />
                     @Extensions
                  </Link>
                </li>
                <li style={{ cursor: "pointer", marginBottom: "5px" }}>
                  <Link to={'Categories'}>
                    <i className="fas fa-folder" style={{ margin: "5px" }} />
                     @Categories
                  </Link>
                </li>

                <li style={{ cursor: "pointer", marginBottom: "5px" }}>
                  <Link to={`settings`}>
                    <i className="fas fa-cog" style={{ margin: "5px" }} />
                     @Settings
                  </Link>
                </li>
              </ul>
            </>
          }
        />
      </Routes>
    </div> 
  )
}


export default SpaceDashboard;