import React from "react";
import styled from "styled-components";

const Container = styled("div")`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  font-family: Arial, sans-serif;
`;

const Column = styled("div")<{ primary?: boolean }>`
  flex: ${({ primary }) => (primary ? 2 : 1)};
  margin: 10px;
`;

const ShoppingCart = styled("div")`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const CartHeader = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const CartTitle = styled("h2")`
  font-size: 1.5em;
  color: #333;
`;

const CartOptions = styled("div")`
  color: #007bff;
  cursor: pointer;
`;

const ProductList = styled("div")`
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  margin-top: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const ProductItem = styled("div")`
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;

  &:last-child {
    border-bottom: none;
  }
`;

const ProductImage = styled("img")`
  width: 80px;
  height: auto;
  margin-right: 20px;
`;

const ProductInfo = styled("div")`
  flex: 1;
`;

const ProductName = styled("p")`
  margin: 0;
  font-size: 1em;
  color: #333;
`;

const ProductPrice = styled("div")`
  font-weight: bold;
  color: #333;
`;

const ProductShipping = styled("div")`
  font-size: 0.9em;
  color: #666;
`;

const ProductQuantity = styled("div")`
  display: flex;
  align-items: center;
`;

const QuantityButton = styled("button")`
  padding: 5px;
  background-color: #eee;
  border: none;
  border-radius: 3px;
  margin: 0 5px;
  cursor: pointer;
`;

const OrderSummary = styled("div")`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const SummaryTitle = styled("h3")`
  margin-bottom: 20px;
  font-size: 1.2em;
  color: #333;
`;

const SummaryItem = styled("div")`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  color: #666;
`;

const Total = styled("div")`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  font-weight: bold;
  color: #333;
`;

const CheckoutButton = styled("button")`
  width: 100%;
  padding: 15px;
  background-color: #ff4747;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  margin-top: 20px;
  cursor: pointer;

  &:hover {
    background-color: #ff2e2e;
  }
`;

const PaymentMethods = styled("div")`
  margin-top: 20px;
`;

const BuyerProtection = styled("div")`
  margin-top: 20px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;


const OrderCart = () => {
  return (
    <Container>
      <Column primary>
        <ShoppingCart>
          <CartHeader>
            <CartTitle>Shopping Cart (46)</CartTitle>
            <CartOptions>
              <span>Select all items</span> | <span>Delete selected items</span>
            </CartOptions>
          </CartHeader>
        </ShoppingCart>
        <ProductList>
          <ProductItem>
            <input type="checkbox" checked />
            <ProductImage src="product-image-url-1.jpg" alt="Product" />
            <ProductInfo>
              <ProductName>
                2023 Summer Girls Print Binding Rope Dress
              </ProductName>
              <ProductShipping>Shipping: US $20.32</ProductShipping>
            </ProductInfo>
            <ProductPrice>US $1.91</ProductPrice>
            <ProductQuantity>
              <QuantityButton>-</QuantityButton>
              <span>10</span>
              <QuantityButton>+</QuantityButton>
            </ProductQuantity>
          </ProductItem>
          {/* Repeat ProductItem for other products */}
        </ProductList>
      </Column>
      <Column>
        <OrderSummary>
          <SummaryTitle>Summary</SummaryTitle>
          <SummaryItem>
            <span>Subtotal</span>
            <span>US $19.10</span>
          </SummaryItem>
          <SummaryItem>
            <span>Shipping fee</span>
            <span>US $20.32</span>
          </SummaryItem>
          <SummaryItem>
            <span>Saved</span>
            <span>-US $1.20</span>
          </SummaryItem>
          <Total>
            <span>Total</span>
            <span>US $38.22</span>
          </Total>
          <CheckoutButton>Checkout (1)</CheckoutButton>
        </OrderSummary>
        <PaymentMethods>
          <img src="payment-methods.jpg" alt="Payment Methods" />
        </PaymentMethods>
        <BuyerProtection>
          <p>
            Get full refund if the item is not as described or if it is not
            delivered
          </p>
        </BuyerProtection>
      </Column>
    </Container>
  );
};

export default OrderCart;
