import { gql } from "@apollo/client";
// fragments
const catIcon = gql`
    fragment catIcon on Icon {
        filename originName extension
    }
`
// queries
export const READ_CATEGORIES = gql`
    ${catIcon}
    query admin_category_read_categories {
        admin_category_read_categories{_id name description icon {...catIcon}}
    }
`
export const WRITE_CATEGORY = gql`
    mutation admin_category_write_category($category: _Category, $icon: _Icon){
        admin_category_write_category(category: $category, icon: $icon)
    }
`;
export const READ_CATEGORY_DETAILS = gql`
    ${catIcon}
    query admin_category_read_categoryDetails($_id: ID!){
        admin_category_read_categoryDetails(_id: $_id){
            _id name description icon {...catIcon} subcategories{_id name description icon {...catIcon}}
        }
    }
`
export const READ_CATEGORY_SEARCH = gql`
    ${catIcon}
    query admin_category_read_searchCategory($q: String!){
        admin_category_read_searchCategory(query: $q){
            _id name description icon {...catIcon}
        }
    }
`
export const READ_CATEGORY_UPDATE = gql`
    mutation admin_category_update_category($_id: ID!, $updates: _Category, $icon: _Icon){
        admin_category_update_category(_id: $_id, updates: $updates, icon:$icon)
    }
`
export const ENABLE_CATEGORY_IN_SPACE = gql`
    mutation space_category_write_enableCategory($_ids: [ID!]) {
        space_category_write_enableCategory(_ids: $_ids)
}`
export const READ_SPACE_CATEGORIES = gql`
    ${catIcon}
    query space_category_read_categories {
        space_category_read_categories{_id name description icon {...catIcon}}
    }
`
export const SPACE_WRITE_CATEGORY = gql`
    mutation space_category_write_category($category: _Category, $icon: _Icon){
        space_category_write_category(category: $category, icon: $icon)
    }
`;
export const SPACE_READ_CATEGORY_DETAILS = gql`
    ${catIcon}
    query space_category_read_categoryDetails($_id: ID!){
        space_category_read_categoryDetails(_id: $_id){
            _id name description icon {...catIcon} subcategories{_id name description icon {...catIcon}}
        }
    }
`