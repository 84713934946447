import React, { useState } from "react";
import { ApolloClient, InMemoryCache, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { createUploadLink } from "apollo-upload-client";
import ImageView from "../components/ProductDisplayer/ProductImageView";

const uploadLink = createUploadLink({ uri: "http://localhost:3001/graphql" });
const customClient = new ApolloClient({
  link: uploadLink,
  cache: new InMemoryCache(),
});

const UPLOAD_FILE = gql`
  mutation fileUpload($file: FileUploadRequest!) {
    fileUpload(file: $file) {
      _id
      filename
      originName
      extension
      error
    }
  }
`;

interface FileUploadResponse {
  _id: string;
  filename: string;
  originName: string;
  extension: string;
  error?: string;
}

interface FileUploadProps {
  uploadedFilesData: FileUploadResponse[];
  setFilesUploadedData: (data: FileUploadResponse[]) => void;
}

const FileUpload: React.FC<FileUploadProps> = ({
  uploadedFilesData,
  setFilesUploadedData,
}) => {
  const [filesUploaded, setFilesUploaded] = useState<FileUploadResponse[]>([]);
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const [uploadFile] = useMutation(UPLOAD_FILE, { client: customClient });

  const handleUpload = async (selectedFile: File) => {
    try {
      if (selectedFile) {
        const { data } = await uploadFile({
          variables: { file: { file: selectedFile } },
        });

        if (data?.fileUpload) {
          setFilesUploaded((prevFiles) => [
            ...prevFiles,
            { ...data.fileUpload },
          ]);
          setFilesUploadedData([...uploadedFilesData, { ...data.fileUpload }]);
        }
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      Array.from(files).forEach((file) => {
        handleUpload(file);
      });
    }
  };

  const handleOpenFileDialog = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div style={{ display: "flex", height: "600px" }}>
      <ImageView
        rawImages={filesUploaded.map(
          (file) => `http://localhost:3001/uploads/${file.filename}`
        )}
      />
    </div>
  );
};

export default FileUpload;