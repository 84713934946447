import React, { useState } from "react";
import ProductChangeTracker from "./ProductChangeTracker";


interface ExistingProductsHandlerProps {
  products: Product[];
  onSaveProductChanges: (productId: string | number, changes: Partial<Product>) => void;
}

const ExistingProductsHandler: React.FC<ExistingProductsHandlerProps> = ({
  products,
  onSaveProductChanges,
}) => {
  const [changesTracker, setChangesTracker] = useState<
    Record<string | number, Partial<Product>>
  >({});
  const [newProducts, setNewProducts] = useState<Product[]>(products); // Local state for products

const handleChange = (
  productId: string | number,
  field: keyof Product | keyof Stock | keyof Price,
  value: any
) => {
  setChangesTracker((prev) => {
    const updatedProduct: Partial<Product | keyof Stock | keyof Price> = { ...prev[productId] };

    if (field === "quantity" || field === "unit") {
      updatedProduct.stock = {
        quantity: updatedProduct.stock?.quantity ?? 0, // Default to 0 if undefined
        unit: updatedProduct.stock?.unit ?? "unit", // Default to empty string if undefined
        [field]: value,
      };
    } else if (field === "currency") {
      updatedProduct.price = {value: updatedProduct.price?.value?? 20,currency: value || "MAD"}; // Handle price
    } else if (field === "value") {
      updatedProduct.price = {value: parseInt(value) || 20}; // Handle price
    }
    else if (field === "categories") {
      updatedProduct.categories = value; // Handle categories update correctly
    } else {
      updatedProduct[field as keyof Product] = value;
    }
    setNewProducts((prev) =>
      prev.map((product) =>
        product._id === productId ? { ...product, ...updatedProduct } : product
      )
    );
    return { ...prev, [productId]: updatedProduct };
  });
};

  const handleSave = (productId: string | number) => {
    const changes = changesTracker[productId];

    if (!changes || Object.keys(changes).length === 0) {
      console.log(`No changes to save for product ${productId}.`);
      return;
    }

    // Update the local products state to reflect the change
    setNewProducts((prev) =>
      prev.map((product) =>
        product._id === productId ? { ...product, ...changes } : product
      )
    );

    onSaveProductChanges(productId, changes);

    setChangesTracker((prev) => {
      const { [productId]: _, ...rest } = prev; // Remove the saved changes from tracker
      return rest;
    });
  };

  React.useEffect(()=>{
    if(products != newProducts){
      setNewProducts(products)
    }
  },[products])

  
  return (
    <ProductChangeTracker
      products={newProducts}
      onChange={handleChange}
      onSave={handleSave}
      changesTracker={changesTracker}
    />
  );
};

export default ExistingProductsHandler;