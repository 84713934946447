import { Breadcrumbs } from "@ui";
import * as React from "react";
import { Link, Route, Routes } from "react-router-dom";
import { SpaceCategoriesList } from "./SpaceCategoriesList";
import { SpaceEnableCategories } from "./EnableCategories.v2";
import { SpaceAddCategory } from "./SpaceAddCategory";

export const ROUTE_BASE = "category";

export const AdminCategoryDash: React.FC<any> = ({}) => {
  return (
    <div style={{ width: "inherit" }}>
      <Breadcrumbs />
      <Routes>
        <Route path="CreateCategory" element={<SpaceAddCategory />} />
        <Route path="Category_List" element={<SpaceCategoriesList />} />

        <Route
          path="/"
          element={
            <>
              <h2 onClick={() => console.log("clicked")}>Category Menu</h2>

              <h4>Demarrer</h4>
              <ul style={{ listStyle: "none" }}>
                <li style={{ cursor: "pointer", marginBottom: "5px" }}>
                  <Link to={`CreateCategory`}>
                    <i className="fas fa-file" />
                    Create category
                  </Link>
                </li>
                
                <li style={{ cursor: "pointer", marginBottom: "5px" }}>
                  <Link to={`Category_List`}>
                    <i className="fas fa-list" aria-hidden="true" />
                    Catogories List
                  </Link>
                </li>
              </ul>
            </>
          }
        />
      </Routes>
    </div>
  );
};

export default AdminCategoryDash;
