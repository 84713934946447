import React from 'react';
import styled, { keyframes, css } from 'styled-components';

// Reusable styles for the button
const defaultButtonStyles = css<{ isDefault: boolean }>`
  background-color: ${(props) =>
    props.isDefault ? '#0056b3' : '#d3d3d3'}; /* Default color and gray */
  color: white;
  border: none;
  border-radius: 4px;
  padding: 6px 10px;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 2;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: ${(props) =>
      props.isDefault ? '#004494' : '#a9a9a9'}; /* Hover color */
  }
`;

const SliderContainer = styled("div")<{ width?: string; height?: string }>`
  width: ${(props) => props.width || '55%'};
  height: ${(props) => props.height || '600px'};
  margin-left: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  background-color: #f9f9f9;
`;

const PlaceholderText = styled("div")`
  font-size: 16px;
  color: #555;
  text-align: center;
`;

const Image = styled("img")`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Arrow = styled("div")<any>`
  position: absolute;
  top: 50%;
  width: 30px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
  ${(props) => (props.left ? 'left: 0' : 'right: 0')};
`;

const DefaultButton = styled("div")<{ isDefault: boolean }>`
  position: absolute;
  top: 10px;
  right: 10px;
  ${defaultButtonStyles}
`;


interface SliderProps {
  images: string[];
  currentIndex: number;
  defaultImageIndex: number;
  onNext: () => void;
  onPrev: () => void;
  onSetDefault: (index: number) => void;
}

const Slider: React.FC<SliderProps> = ({
  images,
  currentIndex,
  defaultImageIndex,
  onNext,
  onPrev,
  onSetDefault,
}) => {
  const handleSetDefault = () => {
    onSetDefault(currentIndex);
  };

  return (
    <SliderContainer>
      {images.length > 0 ? (
        <>
          <Arrow left onClick={onPrev}>
            &#9664;
          </Arrow>
          <Image
            src={images[currentIndex]}
            alt={`Product Image ${currentIndex + 1}`}
          />
          <Arrow onClick={onNext}>&#9654;</Arrow>
          <DefaultButton
            isDefault={currentIndex === defaultImageIndex}
            onClick={handleSetDefault}
          >
            {currentIndex === defaultImageIndex
              ? 'Default Image'
              : 'Set as Default'}
          </DefaultButton>
        </>
      ) : (
        <PlaceholderText>
          You have no images. Click to upload your images.
        </PlaceholderText>
      )}
    </SliderContainer>
  );
};

export default Slider;