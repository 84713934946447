import { Modal } from '@ui';
import React, { useState } from 'react';

// Types for Product Options
type OptionType = 'select' | 'radio' | 'color' | 'text' | 'number' | 'boolean';

interface OptionValue {
  label: string;
  value: string | number | boolean;
  color?: string;
  priceModifier?: number;
}

interface ProductOption {
  name: string;
  type: OptionType;
  values?: OptionValue[];
  required: boolean;
}

const ProductOptionsModal = ({ setProductOptions, showAddOptions, setShowAddOptions }: any) => {
  const [options, setOptions] = useState<ProductOption[]>([]);
  const [newOption, setNewOption] = useState<ProductOption>({
    name: '',
    type: 'select',
    values: [],
    required: false,
  });

  // Handle adding a new option to the options list
  const handleAddOption = () => {
    setOptions([...options, newOption]);
    setNewOption({ name: '', type: 'select', values: [], required: false });
  };

  // Handle setting a new option value
  const handleOptionChange = (
    index: number,
    field: keyof ProductOption,
    value: any
  ) => {
    const updatedOptions: any = [...options];
    updatedOptions[index][field] = value;
    setOptions(updatedOptions);
  };

  // Handle adding values to a new option
  const handleAddValueToOption = () => {
    setNewOption({
      ...newOption,
      values: [...(newOption.values || []), { label: '', value: '' }],
    });
  };

  React.useEffect(() => {
    setProductOptions(options);
  }, [options]);

  return (
    <Modal isOpen={showAddOptions} openModal={setShowAddOptions} header="Add New Option">
      <h3 style={{ fontWeight: 'bold', marginBottom: '1em' }}>Option Preview</h3>
      
      {/* Display Existing Options */}
      {options.map((option, index) => (
        <fieldset key={index} style={styles.fieldset}>
          <legend style={styles.legend}>{option.name}</legend>
          {option.type === 'select' || option.type === 'radio' ? (
            <select style={styles.input}>
              {option.values?.map((val: any, idx) => (
                <option key={idx} value={val.value}>
                  {val.label}
                </option>
              ))}
            </select>
          ) : option.type === 'color' ? (
            <div style={{ display: 'flex', gap: '5px' }}>
              {option.values?.map((val, idx) => (
                <span
                  key={idx}
                  style={{
                    backgroundColor: val.color,
                    width: '20px',
                    height: '20px',
                    display: 'inline-block',
                    borderRadius: '50%',
                  }}
                  title={val.label}
                />
              ))}
            </div>
          ) : option.type === 'boolean' ? (
            <input type="checkbox" style={styles.checkbox} />
          ) : option.type === 'text' || option.type === 'number' ? (
            <input type={option.type} style={styles.input} />
          ) : null}
        </fieldset>
      ))}

      {/* Form to Add a New Option */}
      <h4 style={styles.subheader}>Add New Option</h4>
      <input
        type="text"
        placeholder="Option Name"
        value={newOption.name}
        onChange={(e) => setNewOption({ ...newOption, name: e.target.value })}
        style={styles.input}
      />
      <select
        value={newOption.type}
        onChange={(e) =>
          setNewOption({ ...newOption, type: e.target.value as OptionType })
        }
        style={styles.input}
      >
        <option value="select">Select</option>
        <option value="radio">Radio</option>
        <option value="color">Color</option>
        <option value="text">Text</option>
        <option value="number">Number</option>
        <option value="boolean">Boolean</option>
      </select>
      <label style={styles.checkboxLabel}>
        Required
        <input
          type="checkbox"
          checked={newOption.required}
          onChange={(e) =>
            setNewOption({ ...newOption, required: e.target.checked })
          }
          style={styles.checkbox}
        />
      </label>

      {/* Adding values for select, radio, and color options */}
      {(newOption.type === 'select' ||
        newOption.type === 'radio' ||
        newOption.type === 'color') && (
        <div style={{ marginTop: '1em' }}>
          <h5 style={{ fontWeight: 'bold' }}>Option Values</h5>
          {newOption.values?.map((val, idx) => (
            <div key={idx} style={{ display: 'flex', gap: '10px', marginBottom: '0.5em' }}>
              <input
                type="text"
                placeholder="Label"
                value={val.label}
                onChange={(e) => {
                  const values = [...(newOption.values || [])];
                  values[idx].label = e.target.value;
                  setNewOption({ ...newOption, values });
                }}
                style={styles.smallInput}
              />
              <input
                type={newOption.type === 'color' ? 'color' : 'text'}
                placeholder="Value"
                value={val.value.toString()}
                onChange={(e) => {
                  const values = [...(newOption.values || [])];
                  values[idx].value = e.target.value;
                  setNewOption({ ...newOption, values });
                }}
                style={styles.smallInput}
              />
              {newOption.type === 'color' && (
                <input
                  type="color"
                  value={val.color || '#000000'}
                  onChange={(e) => {
                    const values = [...(newOption.values || [])];
                    values[idx].color = e.target.value;
                    setNewOption({ ...newOption, values });
                  }}
                  style={styles.colorPicker}
                />
              )}
            </div>
          ))}
          <button type="button" onClick={handleAddValueToOption} style={styles.addButton}>
            Add Value
          </button>
        </div>
      )}

      <button onClick={handleAddOption} style={styles.addButton}>
        Add Option
      </button>
    </Modal>
  );
};

const styles: any = {
  fieldset: {
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: '1em',
    marginBottom: '1em',
  },
  legend: {
    fontWeight: 'bold',
    padding: '0 5px',
    color: '#333',
  },
  input: {
    width: '100%',
    padding: '0.5em',
    margin: '0.5em 0',
    borderRadius: '4px',
    border: '1px solid #ddd',
  },
  smallInput: {
    width: '45%',
    padding: '0.5em',
    borderRadius: '4px',
    border: '1px solid #ddd',
  },
  checkboxLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5em',
    marginBottom: '1em',
  },
  checkbox: {
    transform: 'scale(1.2)',
  },
  colorPicker: {
    width: '40px',
    height: '40px',
    padding: 0,
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  subheader: {
    fontWeight: 'bold',
    marginTop: '1em',
  },
  addButton: {
    padding: '0.5em 1em',
    borderRadius: '4px',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    marginTop: '1em',
  },
};

export default ProductOptionsModal;
