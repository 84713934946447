import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './translations/en.json';
import frTranslation from './translations/fr.json';
import arTranslation from './translations/ar.json';

// Load context for each language separately
const loadContextI18n = async (context:any) => {
  const i18nFiles = {};

  try {
    for (const key of context.keys()) {
      const i18n = await context(key);
      Object.assign(i18nFiles, i18n);
    }
  } catch (error) {
    console.error('Error loading context i18n files:', error);
  }

  return i18nFiles;
};

const loadExtensionI18n = async (lang: any) => {
  try {
    let coreContext, extensionsContext;

    // Define contexts explicitly for each language
    if (lang === 'en') {
      coreContext = require.context('src/core', true, /en\.json$/);
      extensionsContext = require.context('src/extensions', true, /en\.json$/);
    } else if (lang === 'fr') {
      coreContext = require.context('src/core', true, /fr\.json$/);
      extensionsContext = require.context('src/extensions', true, /fr\.json$/);
    } else if (lang === 'ar') {
      coreContext = require.context('src/core', true, /ar\.json$/);
      extensionsContext = require.context('src/extensions', true, /ar\.json$/);
    }

    const coreI18n = await loadContextI18n(coreContext);
    const extensionsI18n = await loadContextI18n(extensionsContext);

    return { ...coreI18n, ...extensionsI18n };
  } catch (error) {
    console.error(`Error loading ${lang} i18n files:`, error);
    return {};
  }
};

export const loadArI18n = async () => {
  try {
    const extensionI18n = await loadExtensionI18n('ar');
    return { ...arTranslation, ...extensionI18n };
  } catch (error) {
    console.error('Error loading ar i18n:', error);
    return arTranslation;
  }
};

export const loadFrI18n = async () => {
  try {
    const extensionI18n = await loadExtensionI18n('fr');
    return { ...frTranslation, ...extensionI18n };
  } catch (error) {
    console.error('Error loading fr i18n:', error);
    return frTranslation;
  }
};

export const loadEnI18n = async () => {
  try {
    const extensionI18n = await loadExtensionI18n('en');
    return { ...enTranslation, ...extensionI18n };
  } catch (error) {
    console.error('Error loading en i18n:', error);
    return enTranslation;
  }
};

const initI18n = async () => {
  try {
    const mergedFrTranslation = await loadFrI18n();
    const mergedEnTranslation = await loadEnI18n();
    const mergedArTranslation = await loadArI18n();

    i18next.use(initReactI18next).init({
      lng: 'en', // if you're using a language detector, do not define the lng option
      debug: true,
      resources: {
        en: { translation: mergedEnTranslation },
        fr: { translation: mergedFrTranslation },
        ar: { translation: mergedArTranslation }
      }
    });
  } catch (error) {
    console.error('Error initializing i18n:', error);
  }
};

export default initI18n();