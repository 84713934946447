import { useLazyQuery } from "@apollo/client";
import { EditInPlaceTextArea, EditText } from "@ui";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ADMIN_READ_PRODUCT_BY_ID } from "../../controller/pmController";
import { useSearchParams } from "react-router-dom";
import { PMActions } from "../../store/pmActions";
import { useDispatch } from "react-redux";
import { CategoryCard } from "../ProductList/productList.v2/CategoryCard";
import ProductOptions from "../productOptions/ProductOptions";



const Container = styled.div`
  width: 30%;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
  font-family: Arial, sans-serif;
  margin: 0 15px;
`;

const Header = styled.div`
  background-color: #ff4b4b;
  color: white;
  text-align: center;
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
`;

const PriceSection = styled.div`
  padding: 10px;
  font-size: 24px;
  color: #ff4b4b;
  text-align: left;
  span {
    font-size: 21px !important;
    text-align: left;
  }
`;

const Title = styled.div`
  padding: 10px;
  font-size: 16px;
  color: #333;
  text-align: left;
`;

const CategoyList = styled('div')`
  padding: 10px;
  font-size: 16px;
  color: #333;
  text-align: left;
`;
const ProductDetailsInfo = () => {
  const [headerText, setHeaderText] = useState<string>("Welcome deal");
  const [product, setProduct] = useState<Product | null>(null);
  const [productUpdate, setProductUpdate] = useState<any>(null)
  const [searchParams] = useSearchParams();
  const _id = searchParams.get("_id");

  // store
  const dispatch = useDispatch();

  const [readProduct, { data, loading, error }] = useLazyQuery(ADMIN_READ_PRODUCT_BY_ID);

  const handleHeaderChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setProductUpdate({...productUpdate, discount:{...productUpdate?.discount, title: e.target.value || "Welcome deal"}});

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setProductUpdate({...productUpdate, price: {...productUpdate.price, value: e.target.value }});

  const handlePriceCurrencyChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setProductUpdate({...productUpdate, price: {...productUpdate.price, currency: e.target.value }});

  const handleTitleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setProductUpdate({...productUpdate, title: e.target.value});

  useEffect(() => {
    if (_id) {
      dispatch({type: PMActions.PRODUCT_UPDATE_DRAFT_START, payload: {_id}})
      readProduct({ variables: { _id } });
    }
  }, [_id, readProduct]);

  useEffect(() => {
    if (data?.admin_product_read_productById) {
      setProduct(data.admin_product_read_productById);
    }
  }, [data]);

  React.useEffect(() => {
    if(productUpdate){
      dispatch({type: PMActions.PRODUCT_UPDATE_DRAFT_UPDATE, payload: {_id, ...productUpdate}})
    }
  }, [productUpdate])

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  console.log(productUpdate);
  return (
    <Container>
      <Header>
        <EditText 
          initialData={headerText} 
          saveChange={handleHeaderChange} 
          type="text" 
          name="header"
        />
      </Header>

      <PriceSection>
        <EditText
          initialData={product?.price?.currency || "MAD"}
          saveChange={handlePriceCurrencyChange}
          type="string"
          name="currency"
        />
        <EditText
          initialData={product?.price?.value.toString() || "9.97"}
          saveChange={handlePriceChange}
          type="number"
          name="price"
        />
         
      </PriceSection>
      
      <Title>
        <EditInPlaceTextArea
          initialData={product?.title || "Product Title Here"}
          saveChange={handleTitleChange}
          name="productTitle"
        />
      </Title>

      <PriceSection>
        Categories    
      </PriceSection>
       <CategoyList style={{display: "flex", flexWrap: "wrap"}}>
        {product?.categories?.map((ct: Category) => <CategoryCard category={ct} onRemove={()=>console.log("removed")}/>)}
      </CategoyList> 
      <ProductOptions />
    </Container>
  );
};

export default ProductDetailsInfo;