import styled from 'styled-components';

const Sidebar = styled.div`
  width: 300px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
`;

const FilterGroup = styled.div`
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;

  &:last-child {
    border-bottom: none;
  }
`;

const FilterTitle = styled.h3`
  font-size: 18px;
  margin-bottom: 10px;
  cursor: pointer;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const Checkbox = styled("input").attrs({ type: 'checkbox' })`
  margin-right: 10px;
`;

const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const Radio = styled("input").attrs({ type: 'radio' })`
  margin-right: 10px;
`;

const PriceRangeInput = styled("input").attrs({ type: 'number' })`
  width: 80px;
  padding: 5px;
  margin: 0 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const PriceRangeButton = styled.button`
  padding: 5px 10px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

function ProductFilters() {
  return (
    <Sidebar>
      <FilterGroup>
        <FilterTitle>Delivery options & services</FilterTitle>
        <CheckboxLabel>
          <Checkbox />
          Choice
        </CheckboxLabel>
        <CheckboxLabel>
          <Checkbox />
          Free shipping
        </CheckboxLabel>
      </FilterGroup>

      <FilterGroup>
        <FilterTitle>Shipping from</FilterTitle>
        <RadioGroup>
          <RadioLabel>
            <Radio name="shipping" value="all" />
            All
          </RadioLabel>
          <RadioLabel>
            <Radio name="shipping" value="turkey" />
            Turkey
          </RadioLabel>
          <RadioLabel>
            <Radio name="shipping" value="china" />
            China
          </RadioLabel>
        </RadioGroup>
      </FilterGroup>

      <FilterGroup>
        <FilterTitle>Price</FilterTitle>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '10px',
          }}
        >
          <PriceRangeInput placeholder="MAD" />
          -
          <PriceRangeInput placeholder="MAD" />
          <PriceRangeButton>OK</PriceRangeButton>
        </div>
        <RadioGroup>
          <RadioLabel>
            <Radio name="price" value="under-47.58" />
            Under-47.58
          </RadioLabel>
          <RadioLabel>
            <Radio name="price" value="47.58-79.93" />
            47.58-79.93
          </RadioLabel>
          <RadioLabel>
            <Radio name="price" value="79.93-185" />
            79.93-185
          </RadioLabel>
          <RadioLabel>
            <Radio name="price" value="185-654" />
            185-654
          </RadioLabel>
          <RadioLabel>
            <Radio name="price" value="654-over" />
            654-Over
          </RadioLabel>
        </RadioGroup>
      </FilterGroup>
    </Sidebar>
  );
}

export default ProductFilters;