import React from 'react';
import styled from 'styled-components';

const SecuritySection = styled("div")`
  padding: 10px;
  border-bottom: 1px solid #ddd;
`;

const SecurityHeader = styled("div")`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const SecurityIcon = styled("span")`
  font-size: 18px;
  margin-right: 5px;
`;

const SecurityText = styled("div")`
  font-size: 14px;
`;


const Security = () => (
  <SecuritySection>
    <SecurityHeader>
      <SecurityIcon role="img" aria-label="lock">🔒</SecurityIcon>
      Security & Privacy
    </SecurityHeader>
    <SecurityText>
      Safe payments: We do not share your personal details with any third
      parties without your consent.
    </SecurityText>
    <SecurityText>
      Secure personal details: We protect your privacy and keep your
      personal details safe and secure.
    </SecurityText>
  </SecuritySection>
);

export default Security;
