import * as React from 'react';
import { SearchContainer } from '../../../ui/ui_search';
import { Input } from '../../../..'

interface SearchProps {
  onSearch: (value: string) => void;
  placeholder?: string
}

export const Search: React.FC<SearchProps> = ({ onSearch, placeholder }) => {
  const [inputValue, setInputValue] = React.useState('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
    onSearch(value); // Trigger the callback with the search value
  };

  return (
    <SearchContainer border>
      <Input 
        value={inputValue}
        onChange={handleChange}
        placeholder={placeholder} 
        border="none" 
      />
      <span>
        <i className="fas fa-search" />
      </span>
    </SearchContainer>
  );
};