import React from 'react';

type Props = {};

export const UserWebPasswordReset = (props: Props) => {
  return (
    <div>
      <label htmlFor="email">Password Reset</label>
      <input type="email" placeholder="email" />
    </div>
  );
};
