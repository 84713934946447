import React, { useState } from "react";
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  useMutation,
} from "@apollo/client";
import gql from "graphql-tag";
import { createUploadLink } from "apollo-upload-client";
import { uploadService } from "src/store/config";
const uploadLink = createUploadLink({ uri: `${uploadService}/graphql` });
const customClient = new ApolloClient({
  link: uploadLink,
  cache: new InMemoryCache(),
});

const UPLOAD_FILE = gql`
  mutation fileUpload($file: FileUploadRequest!) {
    fileUpload(file: $file) {
      _id
      filename
      originName
      extension
      error
    }
  }
`;

function FileUpload({uploadedFilesData, setFilesUploadedData}: any) {
  const [file, setFile] = useState<any>(null);
  const [uploadFile] = useMutation(UPLOAD_FILE, { client: customClient });
  const [isDownloaded, setDownloaded] = useState<boolean>(false);
  const [filesUploaded, setFilesUploaded] = useState<any[]>([]);
  const fileInputRef = React.useRef<any>(null);
  
  const handleUpload = async (selectedFile: any) => {
    try {
      if (selectedFile) {
        setDownloaded(true);
        const { data } = await uploadFile({
          variables: { file: { file: selectedFile } },
        });
        
        let tmpfiles = filesUploaded;
        tmpfiles.push(selectedFile);
        setFilesUploaded(tmpfiles);

        setFilesUploadedData([...uploadedFilesData, {...data.fileUpload}]);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };
  const handleFileChange = (event: any) => {
    setDownloaded(false);
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    handleUpload(selectedFile);
  };

  return (
    <div style={{ display: "flex", marginBottom: "20px" }}>
      {filesUploaded.length > 0 &&
        filesUploaded.map((fileUploaded) => (
          <div
            style={{
              display: "flex",
              margin: "5px",
              padding: "5px",
              height: "100px",
              border: "1px dashed gray",
              flexWrap: "wrap",
            }}
          >
            <img
              src={URL.createObjectURL(fileUploaded)}
              alt={`Preview of ${fileUploaded?.name}`}
              style={{
                maxWidth: "150px",
                maxHeight: "100px",
                opacity: isDownloaded ? "1" : "0.1",
              }}
            />
          </div>
        ))}
      <div
        style={{
          display: "flex",
          margin: "5px",
          padding: "5px",
          height: "100px",
          border: "1px dashed gray",
        }}
      >
        <input
          type="file"
          accept="image/png, image/gif, image/jpeg"
          onChange={handleFileChange}
          ref={fileInputRef}
        />
        {/* <button onClick={handleUpload} disabled={!file}>
          Upload File
        </button> */}
      </div>
    </div>
  );
}

export default FileUpload;