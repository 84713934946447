import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import * as React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { READ_CATEGORIES, READ_CATEGORY_DETAILS, ENABLE_CATEGORY_IN_SPACE } from "@core/ittyni_category/controller/categoryController";
import { SPACE_ACTIVATED_CATEGORIES } from "../controller/spaceController";

// Styling for the Button
const Button = styled('button')`
  background-color: #28a745;
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  opacity: 0;
  position: absolute;
  top: 10px; /* Position the button at the top of the category item */
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 0.3s ease-in-out;

  &:hover {
    background-color: #218838;
  }
`;

const Container = styled('div')`
  padding: 20px;
`;

const Title = styled('h1')`
  margin-bottom: 20px;
`;

const CategoryList = styled('ul')`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
`;

const CategoryItem = styled('li')`
  box-shadow: 0 0 5px 0 #888888;
  border-radius: 30px;
  padding: 16px;
  width: 120px;
  margin: 10px;
  cursor: pointer;
  position: relative; /* Allows the button to be positioned relative to this container */

  &:hover ${Button} {
    opacity: 1; /* Show the button when the item is hovered */
  }
`;

const CategoryIcon = styled('div')`
  background-size: contain;
  background-repeat: no-repeat;
  height: 150px;
`;

const CategoryName = styled('div')`
  font-size: 21px;
  font-weight: bold;
  font-family: cursive;
`;

const CategoryDescription = styled('div')`
  margin-top: 10px;
`;

export const SpaceCategories: React.FC<any> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const _id = queryParams.get('_id');

  const {
    data: categoriesData,
    loading: categoriesDataLoading,
    error: categoriesError,
    refetch: categoriesRefetch,
  } = useQuery(SPACE_ACTIVATED_CATEGORIES);

  const [categoryDetails, { data: categoryDetailsData, loading: categoryDetailsLoading }] = useLazyQuery(READ_CATEGORY_DETAILS);

  const [enableCategory, { loading: enableCategoryLoading, error: enableCategoryError }] = useMutation(ENABLE_CATEGORY_IN_SPACE, {
    onCompleted: () => {
      categoriesRefetch(); // Refetch the list after the mutation is complete
    },
  });

  React.useEffect(() => {
    categoriesRefetch();
  }, [categoriesRefetch]);

  React.useEffect(() => {
    if (_id) {
      categoryDetails({ variables: { _id } });
    }
  }, [_id, categoryDetails]);

  const handleCategoryClick = (categoryId: string) => {
    navigate(`../Category_List?_id=${categoryId}`);
  };

  const handleEnableCategory = (_id: string) => {
    enableCategory({
      variables: { _ids: [_id] },
    });
  };

  if (categoriesDataLoading || categoryDetailsLoading) return <div>Loading...</div>;
  if (categoriesError) return <div>Error loading categories.</div>;

  // Separate enabled and existed categories
  const enabledCategories = categoriesData?.space_space_read_activatedCategories.filter((category: any) => category.enabled);
  const existedCategories = categoriesData?.space_space_read_activatedCategories.filter((category: any) => !category.enabled);

  return (
    <Container>
      <Title>Category Manager</Title>
      {_id && categoryDetailsData ? (
        <div>
          <Link to="/admin/khmammed/category/Category_List">Back to All Categories</Link>
          <CategoryItem>
            <CategoryIcon
              style={{
                backgroundImage: `url("https://b9df-105-71-7-253.ngrok-free.app/images/${categoryDetailsData.admin_category_read_categoryDetails?.icon?.filename}")`,
              }}
            />
            <CategoryName>{categoryDetailsData.admin_category_read_categoryDetails?.name}</CategoryName>
            <CategoryDescription>{categoryDetailsData.admin_category_read_categoryDetails.description}</CategoryDescription>
          </CategoryItem>
          <Title>SubCategories</Title>
          <CategoryList>
            {categoryDetailsData?.admin_category_read_categoryDetails?.subcategories.map((subcategory: any, index: number) => (
              <CategoryItem
                key={subcategory?._id}
                onClick={() => handleCategoryClick(subcategory?._id)}
              >
                <CategoryIcon
                  style={{
                    backgroundImage: `url("https://b9df-105-71-7-253.ngrok-free.app/images/${subcategory.icon?.filename}")`,
                  }}
                />
                <CategoryName>{subcategory?.name}</CategoryName>
                <CategoryDescription>{subcategory?.description}</CategoryDescription>
              </CategoryItem>
            ))}
          </CategoryList>

        </div>
      ) : (
        <>
          <Title>Enabled Categories</Title>
          <CategoryList>
            {enabledCategories?.map((category: any) => (
              <CategoryItem key={category._id}>
                <CategoryIcon
                  style={{
                    backgroundImage: `url("https://b9df-105-71-7-253.ngrok-free.app/images/${category.icon?.filename}")`,
                  }}
                />
                <CategoryName>{category.name}</CategoryName>
                <CategoryDescription>{category.description}</CategoryDescription>
              </CategoryItem>
            ))}
          </CategoryList>

          <Title>Existed Categories</Title>
          <CategoryList>
            <CategoryItem onClick={() => navigate('../CreateCategory')}>
              <div>+</div>
              <div>Click to add new Category</div>
            </CategoryItem>
            {existedCategories?.map((category: any) => (
              <CategoryItem key={category._id}>
                <CategoryIcon
                  style={{
                    backgroundImage: `url("https://b9df-105-71-7-253.ngrok-free.app/images/${category.icon?.filename}")`,
                  }}
                />
                <CategoryName>{category.name}</CategoryName>
                <CategoryDescription>{category.description}</CategoryDescription>

                {/* The button that shows on hover */}
                <Button onClick={() => handleEnableCategory(category._id)} disabled={enableCategoryLoading}>
                  {enableCategoryLoading ? "Enabling..." : "Enable"}
                </Button>
              </CategoryItem>
            ))}
          </CategoryList>
        </>
      )}
    </Container>
  );
};
