import * as React from 'react';
import { WrapperRightSide } from '../../ui/ui_wrappers';

export const WebRightbar: React.FC<any> = ({ }) => {

    return (
        <WrapperRightSide>
            
        </WrapperRightSide>
    )
}