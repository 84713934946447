import { Bubble } from "@ui";
import * as React from "react";
import styled from "styled-components";

const ActionsContainer = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`;

const ActionButtonContainer = styled.button`
  display: flex;
  align-items: center;
  margin-right: 10px;
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }

  svg {
    margin-right: 5px;
  }
`;

const ActionButton: React.FC<{ iconClass: string; label: string, bgColor?: string, color?: string }> = ({
  iconClass,
  label,
  bgColor,
  color
}) => (
  <Bubble bgColor={bgColor} customStyles={{style:{fontWeight: "bold"}}}>
    <i className={iconClass} style={{color}}/> {label}
  </Bubble>
);
const Actions: React.FC = () => (
  <ActionsContainer>
    <Bubble>
      <i className="fas fa-thumbs-up" style={{ color: "blue" }} /> {10} |{" "}
      <i className="fas fa-thumbs-down" style={{ color: "red" }} /> {5}
    </Bubble>
    <ActionButton iconClass="fas fa-comments" label="Comments" color="blue" />
    <ActionButton iconClass="fas fa-star" label="Recommand" color="#e6c40c"/>
    <ActionButton iconClass="fas fa-share" label="Share" color="blue"/>
  </ActionsContainer>
);

export default Actions