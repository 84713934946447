import React, { useState, useEffect, useRef } from "react";

interface EditTextProps {
  initialData: string | number;
  saveChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type: string;
  name: string;
}

export const EditText: React.FC<EditTextProps> = ({ initialData, saveChange, type, name }) => {
  const [isModeEdit, setModeEdit] = useState<boolean>(false);
  const [textData, setTextData] = useState<string | number>(initialData);
  const inputRef = useRef<HTMLInputElement>(null);

  // Update textData when initialData changes
  useEffect(() => {
    setTextData(initialData);
  }, [initialData]);

  // Focus the input when entering edit mode
  useEffect(() => {
    if (isModeEdit && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isModeEdit]);

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    // If textData is empty, revert to initialData
    if (textData === "") {
      setTextData(initialData);
    } else {
      saveChange(e); // Only save if textData is valid
    }
    setModeEdit(false);
  };

  return (
    <>
      {isModeEdit ? (
        <input
          ref={inputRef}
          placeholder="Click to edit"
          name={name}
          value={textData}
          onBlur={handleBlur}
          onChange={e => setTextData(e.target.value)}
          style={{ width: "auto" }}
          type={type}
          aria-label={`${name} input field`}
        />
      ) : (
        <span onClick={() => setModeEdit(true)} style={{ fontSize: "16px", fontWeight: "bold", cursor: "pointer" }}>
          {textData}
        </span>
      )}
    </>
  );
};