import {styled, IThemeInterface} from '../..';

export const MenuIcon = styled('div')`
    
    i {
        margin : 0 10px;
        :hover {
            color : red;
            font-size : 18px;
            cursor : pointer;
        }
    }
`