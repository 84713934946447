import { Breadcrumbs } from "@ui";
import * as React from "react";
import { Link, Route, Routes } from "react-router-dom";
import { AdminCreateExtension } from "./adminCreateExtension";
import AdminModulesList from "./adminModuleList";
import  AdminUpdateExtension  from "./adminUpdateExtension";

export const ROUTE_BASE = "components";

export const AdminExtensionDash: React.FC<any> = ({}) => {
  return (
    <div style={{ width: "inherit" }}>
      <Breadcrumbs />
      <Routes>
        <Route path="createExtension" element={<AdminCreateExtension />} />
        <Route path="updateExtension/:_id" element={<AdminUpdateExtension />} />
        <Route path="module_list" element={<AdminModulesList />} />

        <Route
          path="/"
          element={
            <>
              <h2 onClick={() => console.log("clicked")}>user name</h2>

              <h4>Demarrer</h4>
              <ul style={{ listStyle: "none" }}>
                <li>
                  <Link to={`createExtension`}>
                    <i className="fas fa-file" />
                    Nouveau extension
                  </Link>
                </li>
                <li>
                  <Link to={`module_list`}>
                    <i className="fas fa-list" aria-hidden="true" />
                    list components...
                  </Link>
                </li>
              </ul>
            </>
          }
        />
      </Routes>
    </div>
  );
};

export default AdminExtensionDash;
