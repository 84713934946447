import * as React from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import { Panel } from "../../../ui-ittyni";
import { useTranslation } from "react-i18next";
import { SpaceIdentification } from "../components/SpaceIdentification";
import { ENABLE_SPACE, READ_SPACES, WRITE_SPACE } from "../controller/spaceController";
import ModuleGrid from "@core/ittyni_modules/components/GridCard/ModuleGrid";

export const AdminSpaceRegister: React.FC<any> = ({}) => {
  const [accountData, setAccountData] = React.useState<any>();
  const [existedSpaces, setExistedSpaces] = React.useState<any>();
  // read existing space
  const existingSpace = useQuery(READ_SPACES);
  // translation
  const { t } = useTranslation();
  // data requests
  const [writeSpace, writeSpaceData] = useMutation(WRITE_SPACE);
  const readSpaces = useQuery(READ_SPACES);
  const [enableSpace, enableSpaceData] = useMutation(ENABLE_SPACE);

  React.useEffect(() => {
    const spacesfetched = readSpaces?.data?.admin_space_read_spaces;
    if (spacesfetched) {
      setExistedSpaces(
        spacesfetched.map((module: any) => ({
          _id: module._id,
          photo: module.photos?.length>0?module.photos[0]: "",
          title: module.name,
          description: module.description,
          buttonLabel: "I own this business",
          onClick: (_id: string) => enableSpace({variables: {_id}})
        }))
      );
    }
  }, [readSpaces?.data]);

  return (
    <div style={{ width: "100%" }}>
      <h1>{t("space.title")}</h1>
      <p style={{ marginBottom: "45px" }}>
        Vous pouvez ajouter votre espace de travail pour gerer et manager
      </p>

      <Panel
        Header={
          <div>
            <h2>{t("space.subscribedSpaces")}</h2>
          </div>
        }
      >
        <span
          dangerouslySetInnerHTML={{ __html: t("space.noSpaceSubscription") }}
        />
      </Panel>
      <Panel
        Header={
          <div>
            <h2>{t("space.addSpace")}</h2>
          </div>
        }
      >
        <div>
          <SpaceIdentification
            spaceInformation={accountData}
            setSpaceInformation={setAccountData}
          />
        </div>
      </Panel>
      <button
        onClick={() =>
          writeSpace({
            variables: {
              space: {
                photos: [accountData?.space.photo],
                name: accountData?.space.name,
                rating: accountData?.space.rating,
                user_ratings_total: accountData?.space.user_ratings_total,
                location: accountData?.space.location,
                reference: accountData?.space.reference,
              },
            },
          })
        }
      >
        {t("space.activateSpace")}
      </button>
      <Panel
        Header={
          <div>
            <h2>{t("space.subscribedSpaces")}</h2>
          </div>
        }
      >
       
          <ModuleGrid modules={existedSpaces} />

      </Panel>
    </div>
  );
};
