import * as React from "react";
import { useLazyQuery, useMutation } from "@apollo/client";

import { Panel } from "../../../ui-ittyni";
import { useTranslation } from "react-i18next";
import { SpaceIdentification } from "./spaceIdentification";
import { ENABLE_SPACE } from "@core/ittyni_space";

export const AdminUserSpaces: React.FC<any> = ({}) => {
  const [accountData, setAccountData] = React.useState<any>();

  // api request
  const [enableSpace, enableSpaceData] = useMutation(ENABLE_SPACE);
  // translation
  const { t } = useTranslation();

  return (
    <div style={{ width: "100%" }}>
      <h1>My Spaces</h1>
      <p style={{ marginBottom: "45px" }}>
        Vous pouvez ajouter votre espace de travail pour gerer et manager
      </p>

      <Panel
        Header={
          <div>
            <h2>{t("space.subscribedSpaces")}</h2>
          </div>
        }
      >
        <span
          dangerouslySetInnerHTML={{ __html: t("space.noSpaceSubscription") }}
        />
      </Panel>
      <Panel
        Header={
          <div style={{ background: "red" }}>
            <h2>{t("space.addSpace")}</h2>
          </div>
        }
      >
        <div>
          <SpaceIdentification
            spaceInformation={accountData}
            setSpaceInformation={setAccountData}
          />
          
        </div>
      </Panel>
      <button
        onClick={() =>
          enableSpace({
            variables: {
              _id: "66944303455c4b91f6b375fb"
          }})
        }
      >
        {t("space.activateSpace")}
      </button>
    </div>
  );
};
