import { all, fork, takeEvery } from 'redux-saga/effects'
import { AnyAction } from 'redux';
import { tryFetching } from '../../../store/config'
import { UsersActions } from './actions';

/**
 * 
 * @param param0 
 */
function* UsersFetchAll(action : AnyAction){
    yield tryFetching(
        action,
        UsersActions.LIST_ALL_USERS_SUCCESS
    )
}
/**
 * 
 * add new user
 */
function* UsersAddNew(action : AnyAction){
    yield tryFetching(
        action,
        UsersActions.USERS_ADD_NEW_CLIENT_SUCCESS
    )
}
/**
 * 
 * @users linkClientToAccount
 */
function* linkClientToAccount(action : AnyAction){
    yield tryFetching(
        action,
        UsersActions.USERS_ADD_NEW_CLIENT_SUCCESS
    )
}
/**
 * 
 * @users addExtensionsToAccount
 */
function* addExtensionsToAccount(action : AnyAction){
    yield tryFetching(
        action,
        UsersActions.USERS_ADD_EXTENSIONS_TO_ACCOUNT_SUCCESS
    )
}
/**
 * 
 * @users addExtensionsToAccount
 */
function* fetchUserDetails(action : AnyAction){
    yield tryFetching(
        action,
        UsersActions.USERS_FETCH_USER_DETAILS_SUCCESS
    )
}

//watcher func dispatcher
function* watchUsers(){

    // users saga action
    yield takeEvery(UsersActions.LIST_ALL_USERS, UsersFetchAll)
    // users add new user
    yield takeEvery(UsersActions.USERS_ADD_NEW_CLIENT, UsersAddNew)
    // @users Link client to account
    yield takeEvery(UsersActions.USERS_LINK_CLIENT_TO_ACCOUNT, linkClientToAccount)
    // @users Link client to account
    yield takeEvery(UsersActions.USERS_ADD_EXTENSIONS_TO_ACCOUNT, addExtensionsToAccount)
    // @users Link client to account
    yield takeEvery(UsersActions.USERS_FETCH_USER_DETAILS, fetchUserDetails)
    // @users GET subscribed Accounts
    yield takeEvery(UsersActions.USERS_FETCH_SUBSCRIBED_ACCOUNTS, (action : AnyAction)=>tryFetching(
        action,
        UsersActions.USERS_FETCH_SUBSCRIBED_ACCOUNTS_SUCCESS
    ))

}

export function* UsersSagas(){
    yield all([fork(watchUsers)])
}

export default UsersSagas;