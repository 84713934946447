import React from 'react';
import ModuleCard from './ModuleCard';
import { Grid } from './ModuleGridStyles';



const ModuleGrid = ({modules}: any) => {
  return (
    <Grid>
      {modules?.map((module: any, index: any) => (
        <ModuleCard key={index} {...module} />
      ))}
    </Grid>
  );
};

export default ModuleGrid;
