import * as React from "react";
import { Bubble, Modal, Input, ListItem } from "../../../ui-ittyni";
import { useTranslation } from "react-i18next";
import { usePlacesAutocomplete } from "src/utils/usePlacesAutocomplete";

export const SpaceFinder: React.FC<{showModal: boolean, setShowModal: (b:boolean)=>void, [key: string]: any}> = ({
  showModal,
  setShowModal,
  onSelectSpace,
}) => {
  const { t } = useTranslation();

  // load data on scroll

  const [spaceType, setSpaceType] = React.useState<string>("");

  const [query, setQuery] = React.useState<any>();

  const { suggestions } = usePlacesAutocomplete(query);

  return (
    <Modal
      isOpen={showModal}
      openModal={() => setShowModal(!showModal)}
      header={<h3>{t("space.searchSpaceTitle")}</h3>}
    >
      <div>
        <Input
          type="text"
          border
          minHeight={30}
          placeholder={t("space.searchSpace")}
          onChange={({ target }: any) => setQuery(target?.value)}
        />
      </div>

      <div style={{ display: "flex", margin: "0px 15px 15px" }}>
        <Bubble
          onClick={() => setSpaceType("all")}
          isSelected={spaceType === "all"}
        >
          Tous les espace <i className="fas fa-caret-down" />
        </Bubble>
        <Bubble
          onClick={() => setSpaceType("pharma")}
          isSelected={spaceType === "pharma"}
        >
          Pharmacies
        </Bubble>
        <Bubble
          onClick={() => setSpaceType("parapharmacy")}
          isSelected={spaceType === "parapharmacy"}
        >
          ParaPharmacies
        </Bubble>
        <Bubble>Laboratoires</Bubble>
      </div>
      <div id="map" />
      <div>
        <ul
          style={{
            maxHeight: "300px",
            overflowY: "scroll",
            marginTop: "5px",
          }}
        >
          {suggestions?.map((sugg: any) => (
            <ListItem
              key={sugg?.place_id}
              style={{
                alignItems: "center",
                boxSizing: "border-box",
                marginTop: "1px",
                maxWidth: "100%",
                padding: "6px 0",
              }}
              customStyles={{
                display: `
                                         display : flex;
                                         width : 100%;
                                         cursor: pointer;
                                        `,
              }}
              onClick={() => onSelectSpace(sugg)}
            >
              <div style={{ marginRight: "15px" }}>
                <img src={sugg?.icon} height={25} width={25} />
              </div>
              <div
                style={{
                  width: "300px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  margin: "0 15px",
                }}
              >
                {sugg?.name.toUpperCase()}
              </div>
              <div
                style={{
                  width: "300px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  margin: "0 15px",
                }}
              >
                {sugg?.formatted_address}
              </div>
              <div
                style={{
                  width: "auto",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  margin: "0 15px",
                  display: "flex",
                }}
              >
                {sugg?.types?.map((t: string) => (
                  <Bubble
                    key={t}
                    customStyles={{
                      style: { background: sugg?.icon_background_color },
                    }}
                  >
                    {t}
                  </Bubble>
                ))}
              </div>
            </ListItem>
          ))}
        </ul>
      </div>
    </Modal>
  );
};
