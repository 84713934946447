import React from "react";
import styled from "styled-components";
import ProductShipment from "../components/ProductShipment/ProductShipment";
import ProductDisplayer from "../components/ProductDisplayer/ProductDisplayer";
import ProductInfo from "../components/ProductPage/ProductInfo";
import ProductImageView from "../components/ProductDisplayer/ProductImageView";
import { LabelStyled, TextArea, TextAreaFormGroup } from "@ui";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const DetailsSidebar = styled.div`
  display: flex;
  flex-direction: column;
`;
const images = [
  "https://di-uploads-pod23.dealerinspire.com/lexusofnorthborough/uploads/2022/01/Picture3.png",
  "https://media.ed.edmunds-media.com/non-make/carcare/carcare_315114_717.jpg",
  "https://www.shutterstock.com/image-photo/modern-car-flip-key-trinket-600nw-1899490684.jpg",
];
const ProductDetailsPage = () => {
  const [title, setTitle] = React.useState<string | null>(null);

  return (

        <Container>
          <MainContent>
            <ProductDisplayer images={images} />
            <ProductInfo
              avatarUrl="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpmlKSkUcFRuSba3I8DHnEsEHdQNgP5KTSPw&s"
              storeName="Essalhi key Programmer"
              storeSells="1.3k sells"
              likes="2.4k"
              dislikes="5"
              productDescription="Key for hyandai 2012"
            />
          </MainContent>
          <DetailsSidebar>
            <ProductShipment />
          </DetailsSidebar>
        </Container>
  )
};

export default ProductDetailsPage;
