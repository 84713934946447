import { PMActions } from "@extensions/ittyni_products/store/pmActions";
import { Bubble } from "@ui";
import MarkdownEditor from "@uiw/react-markdown-editor";
import React from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

const ActionsContainer = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`;

const ActionButtonContainer = styled.button`
  display: flex;
  align-items: center;
  margin-right: 10px;
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }

  svg {
    margin-right: 5px;
  }
`;

const ProductDescriptionContainer = styled.div`
  margin-top: 20px;
  padding: 0 20px;
`;

const ProductDescription: React.FC<{ description: string }> = ({
  description,
}) => {
  const [desc, setDescription] = React.useState<string | null>(description);
  const [searchParams] = useSearchParams();
  const _id = searchParams.get("_id");
  // store
  const dispatch = useDispatch();

  const handleChange = (value: string) => {
    setDescription(value);
  };

  React.useEffect(() => {
    if (!!desc?.length) {
      dispatch({
        type: PMActions.PRODUCT_UPDATE_DRAFT_UPDATE,
        payload: { _id, description: desc },
      });
    }
  }, [desc]);
  return (
    <ProductDescriptionContainer>
      <h3>Product Description</h3>
      <MarkdownEditor
        height="400px"
        value={description}
        onChange={(value) => handleChange(value)}
      />
    </ProductDescriptionContainer>
  );
};

export default ProductDescription;
