export enum UsersActions {

    /*******************************
     * users  
     *******************************/
    // list users
    LIST_ALL_USERS = '@@users/LIST_ALL_USERS',
    LIST_ALL_USERS_ERROR = '@@users/LIST_ALL_USERS_ERROR',
    LIST_ALL_USERS_SUCCESS = '@@users/LIST_ALL_USERS_SUCCESS',
    // add new user
    USERS_ADD_NEW_CLIENT = '@@users/USERS_ADD_NEW_CLIENT',
    USERS_ADD_NEW_CLIENT_ERROR = '@@users/USERS_ADD_NEW_CLIENT_ERROR',
    USERS_ADD_NEW_CLIENT_SUCCESS = '@@users/USERS_ADD_NEW_CLIENT_SUCCESS',
    // link client to account
    USERS_LINK_CLIENT_TO_ACCOUNT = '@@users/USERS_LINK_CLIENT_TO_ACCOUNT',
    USERS_LINK_CLIENT_TO_ACCOUNT_ERROR = '@@users/USERS_LINK_CLIENT_TO_ACCOUNT_ERROR',
    USERS_LINK_CLIENT_TO_ACCOUNT_SUCCESS = '@@users/USERS_LINK_CLIENT_TO_ACCOUNT_SUCCESS',
    // link client to account
    USERS_ADD_EXTENSIONS_TO_ACCOUNT = '@@users/USERS_ADD_EXTENSIONS_TO_ACCOUNT',
    USERS_ADD_EXTENSIONS_TO_ACCOUNT_ERROR = '@@users/USERS_ADD_EXTENSIONS_TO_ACCOUNT_ERROR',
    USERS_ADD_EXTENSIONS_TO_ACCOUNT_SUCCESS = '@@users/USERS_ADD_EXTENSIONS_TO_ACCOUNT_SUCCESS',
    // link client to account
    USERS_FETCH_USER_DETAILS = '@@users/USERS_FETCH_USER_DETAILS',
    USERS_FETCH_USER_DETAILS_ERROR = '@@users/USERS_FETCH_USER_DETAILS_ERROR',
    USERS_FETCH_USER_DETAILS_SUCCESS = '@@users/USERS_FETCH_USER_DETAILS_SUCCESS',
    // link client to account
    USERS_FETCH_SUBSCRIBED_ACCOUNTS = '@@users/USERS_FETCH_SUBSCRIBED_ACCOUNTS',
    USERS_FETCH_SUBSCRIBED_ACCOUNTS_SUCCESS = '@@users/USERS_FETCH_SUBSCRIBED_ACCOUNTS_SUCCESS',
    

}
