import React from "react";
import ActionButton from "../components/actionButton/ActionButton";
import ModuleGrid from "../components/GridCard/ModuleGrid";
import { useQuery } from "@apollo/client";
import { PUBLISHED_COMPONENTS } from "../controller/moduleController";
import { useNavigate } from "react-router-dom";


const AdminModulesList = () => {
  const [modulesList, setModulesList] = React.useState<any>();

  const navigate = useNavigate();

  const publishedModules = useQuery(PUBLISHED_COMPONENTS, {
    variables: { status: "created" },
  });
  React.useEffect(() => {
    let md = publishedModules?.data?.admin_components_read_componentsWithStatus;
    if (md) {
      setModulesList(
        md.map((module: any) => ({
          _id: module._id,
          icon: module.icon,
          title: module.name,
          description: module.description,
          buttonLabel: "update",
          onClick: (_id: string)=>navigate(`../updateExtension/${_id}`)
        }))
      );
    }
  }, [publishedModules.data?.admin_components_read_componentsWithStatus]);
  React.useEffect(() => {
    publishedModules.refetch();
  },[])
  return (
    <div style={{ padding: "15px" }}>
      <ActionButton onClick={()=> navigate('../createExtension')}/>
      <h3>List of Last Published Modules</h3>
     {modulesList && <ModuleGrid modules={modulesList} />}
    </div>
  );
};

export default AdminModulesList;
