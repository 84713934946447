import React, { useState } from "react";
import { Modal, styled } from "@ui";
import { ProductCategoriesModal } from "./ProductCategoriesModal";
import { ProductListItem } from "./ProductListItem";
// Styled Components
const Container = styled("ul")`
  padding: 0;
  list-style-type: none;
  margin: 10px;

  li {
    padding: 5px;
    min-height: 30px;
    border: 1px solid;
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-top: 10px;
    background: #fff;
    box-shadow: 0px 2px 0px 0px rgb(6 6 6 / 6%);
    display: grid;
    grid-template-columns: 0.2fr 1fr 3fr 1fr 1fr 1fr 0.5fr .5fr;
    align-items: center;

    :hover {
      box-shadow: 0px 2px 0px 2px rgb(6 6 6 / 6%);
    }
  }
`;
// Main ProductChangeTracker Component
const ProductChangeTracker: React.FC<ProductChangeTrackerProps> = ({
  products,
  onChange,
  onSave,
  changesTracker,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentProduct, setCurrentProduct] = useState<Product | null>(null);
  const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);

  const handleOpenModal = (product: Product) => {
    setCurrentProduct(product);
    setSelectedCategories(product.categories || []);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCurrentProduct(null);
  };

  const handleSelectCategories = (selectedCategories: Category[]) => {
    if (currentProduct) {
      onChange(currentProduct._id, "categories", selectedCategories);
      setSelectedCategories(selectedCategories); // Update selected categories state
    }
  };

  return (
    <>
      <Container>
        {products.map((product) => {
          const modifiedFields = changesTracker[product._id] || {};
          const hasUnsavedChanges = !!Object.keys(modifiedFields).length;

          return (
            <ProductListItem
              key={product._id}
              product={product}
              onChange={onChange}
              onSave={onSave}
              hasUnsavedChanges={hasUnsavedChanges}
              modifiedFields={modifiedFields}
              onEditCategories={() => handleOpenModal(product)}
            />
          );
        })}
      </Container>

      {currentProduct && (
        <Modal openModal={handleCloseModal} header={"Select Categories"} isOpen={isModalOpen}>
          <ProductCategoriesModal
            selectedCategories={selectedCategories}
            onSelectCategories={handleSelectCategories} // Pass updated function
          />
        </Modal>
      )}
    </>
  );
};

export default ProductChangeTracker