import * as React from "react";
import MarkdownEditor from "@uiw/react-markdown-editor";
import { useMutation } from "@apollo/client";
import { CREATE_COMPONENT } from "../controller/moduleController";
import svgToString from "@utils/svgToString";

interface Extension {
  name?:string;
  description?: string;
  version?: string;
  space?: string;
  icon?: string;
  iconRaw?: string;
  moduleType?: "core" | "user";
}

export const AdminCreateExtension: React.FC = () => {
  const [extension, setExtension] = React.useState<Extension>({});
  const [iconRaw, setIconRaw] = React.useState<string | null>(null);

  const [createModule, createModuleData] = useMutation(CREATE_COMPONENT);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setExtension((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target?.result as string;
        const formattedSvg: any = svgToString(text);
        if (formattedSvg) {
          setIconRaw(formattedSvg);
          setExtension((prev:any) => ({ ...prev, icon:[{icon_value: formattedSvg, icon_context: "admin" }]}));
        }
      };
      reader.readAsText(file);
    }
  };

  const handleModuleTypeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setExtension((prev) => ({
      ...prev,
      moduleType: event.target.value as "core" | "user",
    }));
  };

  const handleAddComponent = () => {
    createModule({ variables: { ext: extension } });
    // Optionally, handle the data submission to the server here
  };
  return (
    <div style={{ width: "inherit" }}>
      <h1>Add Extension</h1>
      <div>
        <div>
          <label htmlFor="name">Name: </label>
          <input id="name" name="name" onChange={handleInputChange} />
        </div>
        <div>
          <label htmlFor="name">Route Base: </label>
          <input id="route_base" name="route_base" onChange={handleInputChange} />
        </div>
        <div>
          <label htmlFor="icon">Icon: </label>
          <input
            id="icon"
            type="file"
            accept="image/svg+xml"
            onChange={handleFileChange}
          />
        </div>
        {iconRaw && (
          <div>
            <label>Preview: </label>
            <div
              dangerouslySetInnerHTML={{ __html: iconRaw }}
              style={{
                border: "1px solid #ccc",
                padding: "5px",
                width: "50px",
                height: "50px",
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            />
          </div>
        )}
        <div>
          <label htmlFor="version">Version: </label>
          <input id="version" name="version" onChange={handleInputChange} />
        </div>
      </div>
      <div>
          <label htmlFor="mode">Mode: </label>
          <input
            id="mode"
            type="text"
            name="mode"
            onChange={handleInputChange}
          />
        </div>
      <div>
        <label htmlFor="description">Description: </label>
        <MarkdownEditor
          height="400px"
          value={extension.description}
          onChange={(value) =>
            setExtension({ ...extension, description: value })
          }
        />
      </div>
      <div>
        <button onClick={handleAddComponent}>Add Component</button>
      </div>
    </div>
  );
};