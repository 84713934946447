import { IThemeInterface, styled } from "../../";

export const SearchContainer = styled('div')<{
  border?: boolean;
  theme: IThemeInterface;
}>`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin: 10px auto;
  padding: 8px 12px;
  background-color: #f9f9f9;
  border: ${({ border }) => (border ? '1px solid #ddd' : 'none')};
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease;

  input {
    flex: 1;
    border: none;
    outline: none; /* Removes focus outline */
    font-size: 16px;
    background: transparent;
    padding: 0 10px;
    appearance: none; /* Remove browser styling */
    -webkit-appearance: none; /* Safari-specific styling reset */

    &:focus {
      border: none; /* Ensure border stays removed */
      outline: none; /* No outline on focus */
      box-shadow: none; /* No shadow on focus */
    }
    ::-webkit-input-placeholder { 
        color: black;
        background: inherit;
    }
    :-moz-placeholder { 
        color: black;
        background: inherit;
    }
    ::-moz-placeholder { 
        color: black;
        background: inherit;
    }
    :-ms-input-placeholder { 
        color: black;
        background: inherit;
    }
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    cursor: pointer;
    transition: transform 0.2s ease;

    i {
      font-size: 18px;
      color: #606469;
    }

    &:hover {
      transform: scale(1.1);
    }
  }
`;
