// src/NewProjectCard.js
import React from "react";
import {
  Button,
  ContentWrapper,
  Icon,
  TitleWrapper,
  Title,
  Subtitle,
  ImgWrapper,
  Img,
} from "./ActionButtonStyled";

const ActionButton = ({onClick}: any) => (
  <Button onClick={onClick}>
    <ContentWrapper>
      <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <path d="M13 7v1H8v5H7V8H2V7h5V2h1v5z"></path>
      </Icon>
      <TitleWrapper>
        <Title>New Module</Title>
        <Subtitle>Click here if you to create your Own module</Subtitle>
      </TitleWrapper>
    </ContentWrapper>
    <ImgWrapper>
      <Img
        src={
          "https://cloudiotech.com/wp-content/uploads/2023/08/NetSuite-ERP-Customization-2023-The-Ultimate-Guide.jpg"
        }
        alt="New Project"
      />
    </ImgWrapper>
  </Button>
);

export default ActionButton;
