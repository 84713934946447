import React from 'react';
import styled from 'styled-components';

const ShippingSection = styled.div`
  padding: 10px;
  border-bottom: 1px solid #ddd;
`;

const ShippingText = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
`;

const DeliveryDate = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-top: 5px;
`;

const Shipping = () => (
  <ShippingSection>
    <ShippingText>
      <span role="img" aria-label="truck">🚚</span> Free shipping
    </ShippingText>
    <DeliveryDate>Delivery: Aug 16 - 28</DeliveryDate>
  </ShippingSection>
);

export default Shipping;
