import { useMutation } from '@apollo/client';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Input } from '../../../ui-ittyni';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { PUBLIC_USER_WRITE_USERBYPASSWORD } from '../controller/userController';
type Props = {};

const Button = styled('button')`
  background-color: #f5f5f5;
  border: none;
  color: black;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 8px;
  transition-duration: 0.4s;
  cursor: pointer;
  &:hover {
    background-color: #c5c5c5;
  }
`;

export const UserWebSignup: React.FC<any> = (props: Props) => {
  const [user, setUser] = React.useState<{
    email: string;
    password: string;
    confirmPassword: string;
  }>({
    email: '',
    password: '',
    confirmPassword: '',
  });

  const { t } = useTranslation();

  const [signup, { data, loading, error }] = useMutation(
    PUBLIC_USER_WRITE_USERBYPASSWORD,
    {
      context: { clientName: 'users' },
    },
  );
  const handleEntries = ({ target }: any) =>
    setUser({ ...user, [target.name]: target.value });

  React.useEffect(() => {
    if (data?.user_write_authByPassword?.token) {
      localStorage.setItem('TTUID', data.user_write_authByPassword.token);
    }
  }, [data?.user_write_authByPassword]);
  return (
    <div>
      <Input
        type="email"
        name="email"
        placeholder={t('profile.email_message')}
        value={user.email}
        onChange={handleEntries}
      />
      <Input
        type="password"
        name="password"
        placeholder={t('profile.password_message')}
        value={user.password}
        onChange={handleEntries}
      />
      <Input
        type="password"
        name="confirmPassword"
        placeholder={t('profile.password_confirmation_message')}
        value={user.confirmPassword}
        onChange={handleEntries}
      />
      <Button
        onClick={() => signup({ variables: { newUser: user } })}
        disabled={false}
      >
        {false ? 'Loggin in...' : 'Login'}
      </Button>
    </div>
  );
};
