import { useLazyQuery, useQuery } from "@apollo/client";
import {
  GlobalStyles,
  WrapperWeb,
  Header,
  WrapperMain,
  WrapperContent,
  WebRightbar,
  WrapperFooter,
  AccountMenu,
} from "../ui-ittyni";
import * as React from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import AdminSidebar from "@ui/src/layout/sides/AdminSidebar_";
import { INSTALLED_SPACES, READ_SPACE_COMPONENTS, READ_SPACE_MODULES } from "@core/ittyni_user";
import { useSelector } from "react-redux";

/**
 * Get core dashes and create routes
 * in core section
 */
const req = require.context("src/core", true, /\/space\/.*Dash.*\.tsx?$/);
const reqExt = require.context("src/extensions", true, /\/space\/.*Dash.*\.tsx?$/);

const modules = req
  .keys()
  .map((key: any) => ({ dash: req(key).default, path: req(key).ROUTE_BASE }));

const extenions = reqExt
  .keys()
  .map((key: any) => ({ dash: reqExt(key).default, path: reqExt(key).ROUTE_BASE }));


const extensionsMenuItems: any = [
  { icon: <i className="fas fa-box" />, text: "Customers" },
  { icon: <i className="fas fa-tasks" />, text: "Products" },
  { icon: <i className="fas fa-history" />, text: "All Tasks" },
  { icon: <i className="fas fa-truck" />, text: "History" },
  {
    icon: <i className="fas fa-plus-square" />,
    text: "Install Extension",
    link: "/admin/khmammed/extension",
  },
];
export const SpaceApp: React.FC<any> = () => {
  const { userConnected, isAuth } = useSelector(({ user }: any) => user);
  const { spaceId } = useParams();
  // states
  const [coreMenu, setCoreMenu] = React.useState<any>();
  const [extensionMenu, setExtensionMenu] = React.useState<any>();
  const [footerMenu, setFooterMenu] = React.useState<any>();

  // server requests
  const [readSpaceExtensions, spaceExtensions] = useLazyQuery(
    READ_SPACE_COMPONENTS
  );
  const [readSpaceModules, spaceModules] = useLazyQuery(
    READ_SPACE_MODULES
  );

  const [readEnabledSpaces, enabledSpaces] = useLazyQuery(INSTALLED_SPACES);

  if (!userConnected) {
    return <Navigate to="/" replace />;
  }

  React.useEffect(() => {
    const spComponent = spaceExtensions.data?.space_user_read_spaceComponents;
    if (spComponent) {
      setCoreMenu(
        spComponent.map((cm: any) => ({
          icon: cm.icon,
          text: cm.name,
          link: `${cm.route_base}`,
        }))
      );
    }
  }, [spaceExtensions.data]);

  React.useEffect(() => {
    const spModules = spaceModules.data?.space_user_read_spaceComponentsModule;
    if (spModules) {
      setExtensionMenu(
        spModules.map((cm: any) => ({
          icon: cm.icon,
          text: cm.name,
          link: `${cm.route_base}`,
        }))
      );
    }
  }, [spaceModules.data]);

  React.useEffect(() => {if(spaceExtensions.error){setCoreMenu(null)}}, [spaceExtensions.error]);

  React.useEffect(() => {
    if (isAuth) {
      readEnabledSpaces();
      readSpaceExtensions();
      readSpaceModules();
    }
  }, [isAuth]);

  React.useEffect(() => {
    const spaces = enabledSpaces.data?.admin_user_read_installedSpaces;
    if (spaces) {
      setFooterMenu(
        spaces.map((sp: any) => ({
          icon: <i className="fas fa-building" />,
          text: sp.name,
          link: `/space/khmammed/${sp._id}/`,
        }))
      );
    }
  }, [enabledSpaces.data]);

  React.useEffect(() => {
    if (spaceId) {
      localStorage.setItem("space", spaceId);
      spaceExtensions.refetch();
    }
  }, [spaceId]);

  return (
    <>
      <GlobalStyles />

      <WrapperWeb>
        <Header user={{}} />
        <AdminSidebar
          user={{
            username: "space",
            role: "space",
            picture: "picture",
          }}
          coreMenuItems={coreMenu}
          extensionsMenuItems={extensionMenu}
          footerMenuItems={footerMenu}
        />
        <WrapperMain>
          <WrapperContent>
            <Routes>
              {modules.map((module: any) => (
                <Route
                  key={module.path}
                  path={module.path + "/*"}
                  element={<module.dash />}
                />
              ))}
              {extenions.map((module: any) => (
                <Route
                  key={module.path}
                  path={module.path + "/*"}
                  element={<module.dash />}
                />
              ))}
            </Routes>
          </WrapperContent>

          <WebRightbar>this right bar</WebRightbar>
        </WrapperMain>

        <WrapperFooter>footer</WrapperFooter>
      </WrapperWeb>
    </>
  );
};
