import { gql } from "@apollo/client";

// <=================== Fragments
export const NAME_FIELDS = gql`
  fragment NameFields on Name {
    name_lang
    name_value
    name_country
  }
`;
export const GroupFragment = gql`
  fragment GroupFragment on Group {
    attributes {
      transform
      fill
      stroke
    }
    paths {
      d
    }
  }
`;
export const IcoFragment = gql`
  fragment IcoFragment on Ico {
    viewBox
    groups {
      ...GroupFragment
    }
    paths {
      d
    }
  }
  ${GroupFragment}
`;
export const EXTENSION_FIELDS = gql`
  fragment ComponentFields on Extension {
    _id
    icon
    name
    route_base
    description
    status
    version
  }
`;
export const COMPONENT_FIELDS = gql`
  fragment ComponentFields on Component {
    _id
    ico {
      ...IcoFragment
    }
    name {
      name_lang
      name_value
      name_country
    }
    ROUTE_BASE
    description
    status
    version
  }
  ${IcoFragment}
`;

//==================> Fragment End
export const GET_ALL_COMPONENTS = gql`
  ${COMPONENT_FIELDS}
  query GetAllComponents {
    components {
      ...ComponentFields
    }
  }
`;
export const READ_COMPONENT = gql`
  query admin_components_read_component($_id: ObjectId!) {
    admin_components_read_component(_id: $_id) {
      ...ComponentFields
    }
  }
  ${EXTENSION_FIELDS}
`;
export const CREATE_COMPONENT = gql`
  mutation admin_components_write_component($ext: ComponentInput!) {
    admin_components_write_component(ext: $ext) {
      _id
    }
  }
`;
export const UPDATE_COMPONENT = gql`
  mutation admin_components_update_component($_id: ObjectId!, $input: ComponentUpdate!) {
    admin_components_update_component(_id: $_id, input: $input) {
      _id name description status version
    }
  }
`;
export const DELETE_COMPONENT = gql`
  ${COMPONENT_FIELDS}
  mutation DeleteComponent($id: ObjectId!) {
    deleteComponent(id: $id) {
      ...ComponentFields
    }
  }
`;
export const PUBLISHED_COMPONENTS = gql`
  query admin_components_read_componentsWithStatus($status: Status) {
    admin_components_read_componentsWithStatus(status: $status) {
      ...ComponentFields
    }
  }
  ${EXTENSION_FIELDS}
`;
