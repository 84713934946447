import React from 'react';
import styled from 'styled-components';
import ShipTo from './ShipTo';
import Commitment from './Commitment';
import Shipping from './Shipping';
import Security from './Security';
import Quantity from './Quantity';
import Actions from './Actions';
import Icons from './Icons';

const Container = styled.div`
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
  font-family: Arial, sans-serif;
  margin: 0 15px;
  text-align: left;
`;

const ProductShipment = () => (
  <Container>
    <ShipTo />
    <Commitment />
    <Shipping />
    <Security />
    <Quantity />
    <Actions />
    <Icons />
  </Container>
);

export default ProductShipment;
