import styled from 'styled-components';

export const Button = styled('button')`
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  color: #333;
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const Icon = styled('svg')`
  width: 3em;
  height: 3em;
  fill: currentColor;
  margin-right: 16px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  font-weight: 700;
`;

export const Title = styled.div`
  font-size: 1.5em;
`;

export const Subtitle = styled.span`
  font-size: 1em;
  color: #666;
`;

export const ImgWrapper = styled.div`
  margin-left: 16px;
  display: flex;
  align-items: center;
`;

export const Img = styled('img')`
  width: 118.47px;
  height: 100%;
  background: linear-gradient(270deg, #1389fd4d, #20232700);
  border-radius: 8px;
`;