import * as React from "react";
import styled from "styled-components";
import ProductDetailsInfo from "../ProductPage/ProductDetailsInfo.v1";
import ProductImageView from "./ProductImageView";

const ProductDisplayerContainer = styled("div")`
  display: flex;
`;

const Sidebar = styled("div")`
  width: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Thumbnail = styled("img")<{ active?: boolean }>`
  width: 80%;
  height: 80px;
  margin: 10px 0;
  cursor: pointer;
  object-fit: cover;
  border: ${(props) => (props.active ? "2px solid #007bff" : "none")};
`;

const SliderContainer = styled("div")`
  width: 55%;
  margin-left: 20px;
  position: relative;
`;

const Image = styled("img")`
  width: 100%;
  height: 600px;
  object-fit: cover;
`;

const Arrow = styled("div")<{ left?: boolean }>`
  position: absolute;
  top: 50%;
  width: 30px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
  ${(props) => (props.left ? "left: 0" : "right: 0")};
`;


const ProductDisplayer: React.FC<any> = ({images}) => {
  
  return (
    <ProductDisplayerContainer>
      <ProductImageView rawImages={images} />
      <ProductDetailsInfo />
    </ProductDisplayerContainer>
  );
};

export default ProductDisplayer;