import { AnyAction, Reducer } from "redux";
import { SpaceActions } from "./spaceActions";


export const spaceReducer: Reducer = (state = {}, action: AnyAction) => {
    switch (action.type) {
        case SpaceActions.SPACE_ACTIVATED_EXTENSIONS:
            return {
                ...state, extensions: action.payload ,
            };
        case SpaceActions.SPACE_DETAILS:
            return {
                ...state, ...action.payload
            }
        default:
            return { ...state };
    }
}


export default spaceReducer;