import * as React from 'react';
import {
  DropdownContainerStyled,
  IconStyled,
  DropdownMenuStyle,
  DropdownMenuStyled,
  MenuItemStyled,
  MenuFooterStyled,
  DisconnectButtonStyled,
} from '../../../';
import useOnClickOutside from '../../hooks/useOnClickOutside';

export const DropdownMenu: React.FC<{
  title?: string;
  footer?: any;
  setOpen?: any;
  children?: any;
  show?: any;
}> = ({ title, children, show }) => {
  
  const [showMenu, setShowMenu] = React.useState<boolean>(show);
  
 React.useEffect(() => {
    setShowMenu(show);
  }, [show]);
  return (
    <DropdownMenuStyle showMenu={showMenu}>
      {title&&<label>{title}</label>}
        {children}
    </DropdownMenuStyle>
  );
};

export const DropdownMenuContainer: React.FC<any> = ({
  links,
  icon,
  footer,
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <DropdownContainerStyled>
      <IconStyled onClick={toggleMenu}>{icon}</IconStyled>
      <DropdownMenuStyled isOpen={isOpen}>
        {links.map((l: any) => (
          <MenuItemStyled to={l.link}>{l.label}</MenuItemStyled>
        ))}
        {footer && <MenuFooterStyled>{footer}</MenuFooterStyled>}
      </DropdownMenuStyled>
    </DropdownContainerStyled>
  );
};
