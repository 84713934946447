import { ApolloLink, HttpLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { AnyAction } from "redux";
import { call, put } from "redux-saga/effects";
import { store } from "..";

export const uploadService = "https://upload.ittyni.com"
// export const api = "http://165.154.244.169:8060";
export const api = 'https://api.ittyni.com';
// export const api = process.env.NODE_ENV === 'production' ?
//    'https://api.ittyni.com' : 'https://api.ittyni.live';

export async function callApi(
  method: string,
  url: string,
  path: string,
  data?: any
) {
  const res = await fetch(`${url}/${path}`, {
    method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Beer ${localStorage.getItem("TTUID")}`,
      Space: `App ${localStorage.getItem("Space")}`,
    },
    body: JSON.stringify(data),
  });
  return res.json();
}

export function* tryFetching(
  action: AnyAction,
  actionWhenSuccess: string
): any {
  const { path, payload, mw } = action;

  // fetch data
  try {
    const res = yield call(callApi, "post", api, path, payload);

    if (res && res.errors && res.errors[0].message != "null") {
      yield put({
        type: "@@errors/ERROR_FOUNDED",
        message: res.errors[0].message,
        path: path,
        component: action.type.split(/@@|\//)[1],
        action: action.type.split(/@@|\//)[2],
        mw: mw,
        status: "actif",
      });
    } else {
      yield put({
        type: actionWhenSuccess,
        payload: res.data[mw],
        mw: mw,
      });
    }
  } catch (e) {
    yield put({
      type: "@@errors/ERROR_FOUNDED",
      message: e,
      path: path,
      component: action.type.split(/@@|\//)[1],
      action: action.type.split(/@@|\//)[2],
      mw: mw,
      status: "actif",
    });
  }
}

export const apiApollo = new HttpLink({
  uri: `${api}/v1`,
});

export const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const token = localStorage.getItem("TTUID");
  const space = `${localStorage.getItem("space")}`;
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : "",
      space,
    },
  });

  return forward(operation).map((response) => {
    const { data } = response;
    if (data && typeof data === "object") {
      const keys = Object.keys(data);
      if (keys.length > 0) {
        response.data = {
          ...data,
          payload: data[keys[0]],
        };
      }
    }
    return response;
  });
});

export const errorHandling = onError(
  ({ response, graphQLErrors, operation, networkErrors }: any) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, path }: any) => {
        if (path.length > 0) {
          store.dispatch({
            type: "@@errors/ERROR_FOUNDED",
            message: graphQLErrors[0].message,
            path: graphQLErrors[0].path[0],
            component: operation.getContext().clientName,
            status: "actif",
          });
        }
      });
    }
    if (networkErrors) {
      store.dispatch({
        type: "@@errors/ERROR_FOUNDED",
        message: networkErrors,
        path: "network",
        component: "not_set",
        status: "actif",
      });
    }
  }
);
