import * as React from "react";
import GoogleLogin, { GoogleLogout } from "react-google-login";
import { useDispatch, useSelector } from "react-redux";
import { AuthByGoogle, RedirectToWebSite } from "../dispatcher";
import { ReactComponent as User } from "../../../assets/icons/user.svg";
import GoogleOneTapLogin from "react-google-one-tap-login";
import { Link } from "react-router-dom";
import { DropdownMenu, DropdownMenuContainer } from "../../../ui-ittyni";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  ADMIN_USER_READ_ENABLED_EXTENSIONS,
  PUBLIC_USER_READ_AUTHBYTOKEN,
  PUBLIC_USER_WRITE_USERBYOAuth,
} from "../controller/userController";
import { oauthEndPointURL, useGoogleTapLogin, useToken } from "src/utils";
import { UserActions } from "../store/userActions";
// oneTapLoggin by Google
// export const ButtonConnect: React.FC<any> = () => {
//   // state
//   const [showLogin, setshowLogin] = React.useState<boolean>(false);

//   return (
//     <>
//       <GoogleOneTapLogin
//         onError={(error) => console.log(error)}
//         onSuccess={(response) => AuthByGoogle(response)}
//         googleAccountConfigs={{
//           client_id:
//             "802020826418-glf4trufm28c39aor8i1lmqt2d2331a7.apps.googleusercontent.com",
//         }}
//       >
//         <span>
//           <User
//             height="25"
//             width="25"
//             onClick={() => setshowLogin(!showLogin)}
//             style={{ cursor: "pointer" }}
//           />
//         </span>
//       </GoogleOneTapLogin>

//       <div
//         style={{
//           position: "absolute",
//           left: "-70px",
//           background: "white",
//           listStyle: "none",
//           paddingLeft: 0,
//         }}
//       >
//         <ul style={{ listStyle: "none" }}>
//           <li onClick={() => setshowLogin(!showLogin)}>
//             <GoogleLoginButton />
//           </li>
//         </ul>
//       </div>
//     </>
//   );
// };
// google logine button
// google login
export const GoogleLoginButton: React.FC<any> = ({ buttonText }) => {
  const dispatch = useDispatch();
  const [authByGoogle, authByGoogleData] = useMutation(
    PUBLIC_USER_WRITE_USERBYOAuth
  );
  
  const [authByToken, authByTokenData] = useLazyQuery(
    PUBLIC_USER_READ_AUTHBYTOKEN
  );
  React.useEffect(() => {
    if (authByGoogleData?.data?.payload) {
      const token = authByGoogleData.data?.payload.token;
      localStorage.setItem("TTUID", token);
      authByToken({ variables: { token } });
    }
  }, [authByGoogleData.data?.payload]);

  React.useEffect(() => {
    const user = authByTokenData.data?.public_user_read_authByToken;
    if(user){
      dispatch({type: UserActions.WRITE_USER_DETAILS, payload: {userConnected: user, isAuth: true}});
    }
  }, [authByTokenData?.data]);
  return (
    <GoogleLogin
      clientId="802020826418-glf4trufm28c39aor8i1lmqt2d2331a7.apps.googleusercontent.com"
      buttonText={buttonText}
      style={{ justifyContent: "center" }}
      onSuccess={({ profileObj }: any) =>
        authByGoogle({
          variables: {
            user: {
              firstName: profileObj.givenName,
              email: profileObj.email,
              picture: profileObj.imageUrl,
              lastName: profileObj.familyName,
            },
          },
        })
      }
      onFailure={({ response }: any) => console.log(response)}
      cookiePolicy={"single_host_origin"}
    />
  );
};
export const AuthButton: React.FC<any> = ({}, ref) => {
  // get auth status
  const { isAuth, userConnected } = useSelector(({ user }: any) => user);
  // dispath
  const dispatch = useDispatch();
  const token = localStorage.getItem("TTUID");

  const [authByToken, authByTokenData] = useLazyQuery(PUBLIC_USER_READ_AUTHBYTOKEN);
  const [authByGoogle, authByGoogleData] = useMutation(
    PUBLIC_USER_WRITE_USERBYOAuth 
  );

  useGoogleTapLogin(token, {
    client_id:
      "802020826418-glf4trufm28c39aor8i1lmqt2d2331a7.apps.googleusercontent.com",
    disabled: false,
    callback: ({ credential }) => {
      fetch(`${oauthEndPointURL}${credential}`)
        .then((resp) => {
          if (resp?.status === 200 && resp?.json) {
            return resp.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then((res) =>
          authByGoogle({
            variables: {
              user: {
                firstName: res.givenName,
                email: res.email,
                picture: res.imageUrl,
                lastName: res.familyName,
              },
            },
          })
        )
        .catch((error) => {
          throw error;
        });
    },
  });

  React.useEffect(() => {
    const newToken = authByGoogleData.data?.public_user_write_signUpWithGoogle.token
    if(newToken){
      localStorage.setItem('TTUID', newToken);
      authByToken({variables: {token: newToken}})
    }
  }, [authByGoogleData?.data]);

  React.useEffect(() => {
    if(token) {
      authByToken({variables: {token}});
    }
  }, [token]);
  React.useEffect(() => {
    const user = authByTokenData.data?.public_user_read_authByToken;
    if(user) {
      dispatch({type: UserActions.WRITE_USER_DETAILS, payload: {userConnected: user, isAuth: true}});
    }

  }, [authByTokenData?.data]);

  React.useEffect(() => {
    if(authByTokenData.error?.message){
      localStorage.removeItem('TTUID');
    }
  }, [authByTokenData.error]);


  return (
    <div
      style={{
        padding: "0px 15px",
        display: "grid",
        gridTemplateColumns: "auto auto",
        justifyContent: "space-between",
        position: "relative",
        // width:"20%"
      }}
    >
      {isAuth && userConnected && userConnected.email && (
        <div style={{ padding: "5px 0 0 15px", position: "relative" }}>
          <DropdownMenuContainer
            icon={
              <img
                src={userConnected.picture}
                style={{ width: "25px", height: "25px", borderRadius: "7em" }}
              />
            }
            links={[
              {
                link: `/admin/${
                  userConnected.email ? userConnected.email.split("@")[0]+'/' : "#"
                }`,
                label: "admin",
              },
            ]}
            footer={
              <GoogleLogout
                clientId="802020826418-glf4trufm28c39aor8i1lmqt2d2331a7.apps.googleusercontent.com"
                buttonText="Logout"
                onLogoutSuccess={() => {
                  localStorage.removeItem("TTUID");
                  dispatch({ type: UserActions.WRITE_USER_LOGOUT, payload:{userConnected: null, isAuth: false} });
                  RedirectToWebSite("");
                }}
              >
                Deconnecter
              </GoogleLogout>
            }
          />
        </div>
      )}

      {(!isAuth || !token || !userConnected) && (
        <div style={{ padding: "5px" }}>
          <DropdownMenuContainer
            icon={authByTokenData.loading?<span>loading...</span>: <User height="25" width="25" />}
            links={[{ label: "Connect", link: `/web/user/login` }]}
          />
        </div>
      )}
    </div>
  );
};

export const AuthCard: React.FC<any> = (props) => {
  return <div className="card">auth card with social buttons</div>;
};
