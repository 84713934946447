import { EditText, styled } from "@ui";
import * as React from "react";
import { CategoryCard } from "./CategoryCard";
import fallbackImage from "../../../docs/fallback.png";
import { Link } from "react-router-dom";

// Styled EditCategories Icon Button
const EditCategoriesIcon = styled("i")`
  background: orange;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
  cursor: pointer;

  &:hover {
    background: darkorange;
  }
`;

// Helper function to calculate the difference in days
const calculateDaysAgo = (createdAt: string | undefined) => {
  if (!createdAt) return "Unknown";

  const productDate = new Date(createdAt);
  const today = new Date();
  const diffInTime = today.getTime() - productDate.getTime();
  const diffInDays = Math.floor(diffInTime / (1000 * 60 * 60 * 24));

  if (diffInDays === 0) return "Today";
  if (diffInDays === 1) return "1 day ago";
  return `${diffInDays} days ago`;
};

// ProductListItem Component
export const ProductListItem = ({
  product,
  onChange,
  onSave,
  hasUnsavedChanges,
  modifiedFields,
  onEditCategories,
}: {
  product: Product;
  onChange: (
    productId: string | number,
    field: keyof Product | keyof Stock | keyof Price,
    value: any
  ) => void;
  onSave: (productId: string | number, changes: Partial<Product>) => void;
  hasUnsavedChanges: boolean;
  modifiedFields: Partial<Product>;
  onEditCategories: () => void;
}) => {
  const handleSave = () => onSave(product._id, modifiedFields);
  const imageUrl = fallbackImage;

  return (
    <li>
      {/* Product Image */}
      <div>
        <Link to={`../addProduct?_id=${product._id}`}>
        
          <img src={imageUrl} height={25} width={25} alt="Product" />
        </Link>
      </div>

      {/* Product Barcode */}
      <div>
        <EditText
          initialData={product.barcode || "Scan Product Code"}
          saveChange={(e) => onChange(product._id, "barcode", e.target.value)}
          name="barcode"
          type="text"
        />
      </div>

      {/* Product Title */}
      <div>
        <strong>
          <EditText
            initialData={product.title || "Product Title"}
            saveChange={(e) => onChange(product._id, "title", e.target.value)}
            name="title"
            type="text"
          />
        </strong>
      </div>

      {/* Product Categories */}
      <div style={{ display: "flex" }}>
        {product.categories?.map((ct) => (
          <CategoryCard
            key={ct._id}
            category={ct}
            onSelect={() => {}}
            onRemove={() => {
              const updatedCategories = product.categories?.filter(
                (c) => c._id !== ct._id
              );
              onChange(product._id, "categories", updatedCategories);
            }}
          />
        ))}
        <EditCategoriesIcon className="fas fa-edit" onClick={onEditCategories} />
      </div>

      {/* Product Stock */}
      <div>
        <EditText
          initialData={product.stock?.quantity ?? 0}
          type="number"
          saveChange={(e) =>
            onChange(product._id, "quantity", +e.target.value)
          }
          name="quantity"
        />
        <EditText
          initialData={product.stock?.unit || "unit"}
          type="text"
          saveChange={(e) => onChange(product._id, "unit", e.target.value)}
          name="unit"
        />
      </div>

      {/* price edit */}
      <div>
        <strong>
          <EditText
            initialData={product.price?.value || 20}
            saveChange={(e) => onChange(product._id, "value", e.target.value)}
            name="price"
            type="text"
          />
        </strong>
        <strong>
          <EditText
            initialData={product.price?.currency || "MAD"}
            saveChange={(e) => onChange(product._id, "currency", e.target.value)}
            name="price"
            type="text"
          />
        </strong>
      </div>
      {/* Save Button */}
      <div>
        {hasUnsavedChanges && (
          <button onClick={handleSave}>
            <i className="fas fa-save" />
          </button>
        )}
      </div>

      {/* Days Ago Label */}
      <div style={{ marginLeft: "auto", paddingLeft: 10 }}>
        <small>{calculateDaysAgo(product.createdAt)}</small>
      </div>
    </li>
  );
};