import { RouterState, connectRouter } from 'connected-react-router'
import { History } from 'history'
import { combineReducers } from 'redux'

import { fork, all } from 'redux-saga/effects'


export interface IttyniState {
    router: RouterState,
    [key : string]: any
}
/**
 * import dynamically extensions
 * and core reducers
 * @param requireContext 
 * @returns 
 */
const importAllSagas = (requireContext: any) => {
    const sagas: any = [];
    requireContext.keys().forEach((key: any) => {
        const saga = requireContext(key).default;
        if (typeof saga === 'function') {
            sagas.push(saga);
        }
    });
    return sagas;
};

const extensionSagas = importAllSagas(require.context('src/extensions', true, /\/store\/.*saga.*\.ts?$/i));
const coreSagas = importAllSagas(require.context('src/core', true, /\/store\/.*saga.*\.ts?$/i));

/**
 * import dynamically extensions
 * and core reducers
 * @param requireContext 
 * @returns 
 */
const importAllReducers = (requireContext:any) => {
    const reducers: any = {};
    requireContext.keys().forEach((key: any) => {
        const reducerName: any = key.replace('./', '').replace('.ts', '').split('/')[0].split('ittyni_')[1];
        reducers[reducerName] = requireContext(key).default;
    });
    return reducers;
};

const extensionReducers = importAllReducers(require.context('src/extensions', true, /\/store\/.*reducer.*\.ts?$/i));
const coreReducers = importAllReducers(require.context('src/core', true, /\/store\/.*reducer.*\.ts?$/i));



export const createRootReducer = (history: History) =>
    combineReducers({
        router: connectRouter(history),
        ...extensionReducers,
        ...coreReducers
    })
//==============> rootReducer end

export function* rootSaga() {
    yield all([
        ...extensionSagas.map((saga:any) => fork(saga)),
        ...coreSagas.map((saga:any) => fork(saga))
    ])
}
