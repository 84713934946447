import * as React from "react";
import { Link, Route, Routes} from "react-router-dom";
import { AddProduct } from "../components/addProduct";
import { Breadcrumbs, Bubble } from "../../../ui-ittyni";

export const ROUTE_BASE = "products";

const SpaceProductDash: React.FC<any> = () => {
  
  return (
    <div style={{ width: "100%" }}>
      <Breadcrumbs />
      <h1
        style={{
          fontFeatureSettings: "liga 0",
          fontVariantLigatures: "no-contextual",
          fontFamily: "Google Sans ,Arial,sans-serif",
          fontSize: "1.375rem",
          fontWeight: 400,
          lineHeight: "1.75rem",
        }}
      >
      </h1>
      <div style={{display: 'flex', border: "0.5px solid lightgrey"}}>
        <Bubble>
          <Link to={`addProduct`}>add product</Link>
        </Bubble>
      </div>
    <Routes>
      <Route path={`addProduct`} element={<AddProduct />} />
      <Route path="/" element={<span>dash</span>}/>
    </Routes>
    </div>
  );
};

export default SpaceProductDash;