import * as React from "react";

export const ProductDetails: React.FC<any> = ({
  product,
  setProduct,
}) => {
  const handleChange = ({ target }: any) =>
    setProduct({ ...product, [target.name]: target.value });
  return (
    <div>
      <div style={{ margin: "10px 0" }}>
        <label>Reference:</label>
        <input
          type="text"
          name="name"
          value={product?.name || ""}
          onChange={handleChange}
          style={{ width: "60%" }}
        />
      </div>
    </div>
  );
};
