// utils/FormatSvgIcon.ts

export default function svgToString(svgString: string): string {
  const parser = new DOMParser();
  const serializer = new XMLSerializer();
  const doc = parser.parseFromString(svgString, "image/svg+xml");

  const svg = doc.getElementsByTagName("svg")[0];

  if (!svg) {
    throw new Error("Invalid SVG content");
  }

  // Example: remove all style tags
  const styles = svg.getElementsByTagName("style");
  while (styles.length > 0) {
    styles[0].parentNode?.removeChild(styles[0]);
  }

  // Example: set a default viewBox if not already set
  if (!svg.getAttribute("viewBox")) {
    svg.setAttribute("viewBox", "0 0 24 24");
  }

  // Example: remove width and height attributes to make SVG responsive
  svg.removeAttribute("width");
  svg.removeAttribute("height");

  // Add any additional formatting as required
  // For example, adding a specific class name
  svg.setAttribute("class", "formatted-svg-icon");

  const formattedSvgString = serializer.serializeToString(svg);

  return formattedSvgString;
}
