import { useLazyQuery } from "@apollo/client";
import React from "react";
import { useTranslation } from "react-i18next";
import { PUBLIC_USER_READ_AUTHBYTOKEN, PUBLIC_USER_READ_AUTH_WITH_EMAIL } from "../controller/userController";
import { useDispatch, useSelector } from "react-redux";
import { UserActions } from "../store/userActions";
import { Redirect, useHistory } from "react-router";
import { Input, StyledButton } from "@ui";
import { GoogleLoginButton } from "../admin/UserAdminMenu";
import { Link } from "react-router-dom";

type Props = {};

export const UserWebLogin: React.FC<any> = (props: Props) => {
  const { isAuth } = useSelector(({ user }: any) => user);
  const [user, setUser] = React.useState<{
    email: string;
    password: string;
  }>({ email: "", password: "" });
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [authByToken, authByTokenData] = useLazyQuery(PUBLIC_USER_READ_AUTHBYTOKEN);
  const [userReadDetails, userReadDetailsData] = useLazyQuery(
    PUBLIC_USER_READ_AUTH_WITH_EMAIL
  );
  const handleEntries = ({ target }: any) =>
    setUser({ ...user, [target.name]: target.value });

    React.useEffect(() => {
    const user = authByTokenData.data?.public_user_read_authByToken;
    if(user) {
      dispatch({type: UserActions.WRITE_USER_DETAILS, payload: {userConnected: user, isAuth: true}});
    }

  }, [authByTokenData?.data]);
  React.useEffect(() => {
    const token =  userReadDetailsData.data?.public_user_read_loginWithEmail?.token;
    if(token){
      localStorage.setItem('TTUID',token);
      authByToken({variables: {token}});
    }
  }, [userReadDetailsData.data]);
  return (
    <div>
      {isAuth && history.push(`/admin/${user?.email?.split('@')[0]}/dashboard`)}
      {!isAuth && (
        <>
          <label htmlFor="email">{t("profile.username")}</label>
          <Input
            type="email"
            name="email"
            value={user?.email}
            onChange={handleEntries}
            placeholder={t("profile.username_message")}
          />
          <label htmlFor="password">{t("profile.password")}</label>
          <Input
            type="password"
            name="password"
            value={user?.password}
            placeholder={t("profile.password_message")}
            onChange={handleEntries}
            border
          />
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <StyledButton onClick={() => userReadDetails({variables: {...user}})}>
              {userReadDetailsData?.loading
                ? "we Get your information"
                : t("login")}
            </StyledButton>
            <GoogleLoginButton buttonText={t('login')} />
            <p><Link to='/web/user/signup'>{t('profile.signup')}</Link></p>
            <p><Link to='/web/user/reset_password'>{t('profile.reset_password')}</Link></p>
          </div>
        </>
      )}
    </div>
  );
};
