import React from 'react';
import styled from 'styled-components';

const IconSection = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 10px;
`;

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

const Icons = () => (
  <IconSection>
    <IconButton>
      <span role="img" aria-label="favorite">❤️</span>
    </IconButton>
    <IconButton>
      <span role="img" aria-label="share">🔗</span>
    </IconButton>
    <IconButton>
      <span role="img" aria-label="wishlist">🛒</span>
    </IconButton>
  </IconSection>
);

export default Icons;
