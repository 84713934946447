import React, { useState } from 'react';
import styled from 'styled-components';

type OptionType = 'select' | 'radio' | 'color' | 'text' | 'number' | 'boolean';

interface OptionValue {
  label: string;
  value: string | number | boolean;
  color?: string;
  priceModifier?: number;
}

interface ProductOption {
  name: string;
  type: OptionType;
  values?: OptionValue[];
  required: boolean;
}

interface ProductOptionsProps {
  options: ProductOption[];
  onOptionChange: (
    optionName: string,
    value: string | number | boolean
  ) => void;
}

// Styled components for option types
const OptionContainer = styled.div`
  margin-bottom: 20px;
`;

const OptionLabel = styled.label`
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
`;

const SelectInput = styled.select`
  padding: 10px;
  font-size: 16px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;

  input {
    margin-right: 8px;
  }
`;

const ColorContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const ColorOption = styled.div<{ color: string; isSelected: boolean }>`
  width: 30px;
  height: 30px;
  background-color: ${(props) => props.color};
  border-radius: 5px;
  cursor: pointer;
  border: 2px solid ${(props) => (props.isSelected ? '#333' : 'transparent')};
  &:hover {
    border-color: #666;
  }
`;

const TextInput = styled.input`
  padding: 10px;
  font-size: 16px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const CheckboxInput = styled.input`
  margin-right: 8px;
  cursor: pointer;
`;

// Main ProductOptions component
const ProductOptionsDisplayer: React.FC<ProductOptionsProps> = ({
  options,
  onOptionChange,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<{
    [key: string]: string | number | boolean;
  }>({});

  const handleOptionChange = (
    optionName: string,
    value: string | number | boolean
  ) => {
    setSelectedOptions((prev) => ({ ...prev, [optionName]: value }));
    onOptionChange(optionName, value);
  };

  return (
    <div>
      {options.map((option) => (
        <OptionContainer key={option.name}>
          <OptionLabel>
            {option.name} {option.required && '*'}
          </OptionLabel>

          {option.type === 'select' && option.values && (
            <SelectInput
              value={
                typeof selectedOptions[option.name] === 'string' ||
                typeof selectedOptions[option.name] === 'number'
                  ? (selectedOptions[option.name] as string | number)
                  : ''
              }
              onChange={(e) => handleOptionChange(option.name, e.target.value)}
            >
              <option value="" disabled>
                Select {option.name}
              </option>
              {option.values.map((value) => (
                <option
                  key={value.value.toString()}
                  value={value.value.toString()}
                >
                  {value.label}{' '}
                  {value.priceModifier ? `(+${value.priceModifier})` : ''}
                </option>
              ))}
            </SelectInput>
          )}

          {option.type === 'radio' && option.values && (
            <RadioContainer>
              {option.values.map((value) => (
                <RadioLabel key={value.value.toString()}>
                  <input
                    type="radio"
                    name={option.name}
                    value={value.value.toString()}
                    checked={selectedOptions[option.name] === value.value}
                    onChange={() =>
                      handleOptionChange(option.name, value.value)
                    }
                  />
                  {value.label}{' '}
                  {value.priceModifier ? `(+${value.priceModifier})` : ''}
                </RadioLabel>
              ))}
            </RadioContainer>
          )}

          {option.type === 'color' && option.values && (
            <ColorContainer>
              {option.values.map((value) => (
                <ColorOption
                  key={value.value.toString()}
                  color={value.color || '#fff'}
                  isSelected={selectedOptions[option.name] === value.value}
                  onClick={() => handleOptionChange(option.name, value.value)}
                >
                  {value.priceModifier ? `(+${value.priceModifier})` : ''}
                </ColorOption>
              ))}
            </ColorContainer>
          )}

          {option.type === 'text' && (
            <TextInput
              type="text"
              value={(selectedOptions[option.name] as string) || ''}
              onChange={(e) => handleOptionChange(option.name, e.target.value)}
              placeholder={`Enter ${option.name}`}
            />
          )}

          {option.type === 'number' && (
            <TextInput
              type="number"
              value={(selectedOptions[option.name] as number) || ''}
              onChange={(e) =>
                handleOptionChange(option.name, Number(e.target.value))
              }
              placeholder={`Enter ${option.name}`}
            />
          )}

          {option.type === 'boolean' && (
            <label>
              <CheckboxInput
                type="checkbox"
                checked={Boolean(selectedOptions[option.name])}
                onChange={(e) =>
                  handleOptionChange(option.name, e.target.checked)
                }
              />
              Yes / No
            </label>
          )}
        </OptionContainer>
      ))}
    </div>
  );
};

export default ProductOptionsDisplayer;
