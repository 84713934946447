export enum ComponentActions {
    /**
     * fetch all Componets
     */
    COMPONENTS_FETCH_ALL = "@@componet/COMPONENTS_FETCH_ALL",
    COMPONENTS_FETCH_ALL_SUCCESS = "@@componet/COMPONENTS_FETCH_ALL_SUCCESS",
    /**
     * fetch Componets by od
     */
    COMPONENTS_FETCH_BY_ID = "@@componet/COMPONENTS_FETCH_BY_ID",
    COMPONENTS_FETCH_BY_ID_SUCCESS = "@@componet/COMPONENTS_FETCH_BY_ID_SUCCESS",
    /**
     * fetch by name
     */
    COMPONENTS_FETCH_BY_NAME = "@@componet/COMPONENTS_FETCH_BY_NAME",
    COMPONENTS_FETCH_BY_NAME_SUCCESS = "@@componet/COMPONENTS_FETCH_BY_NAME_SUCCESS",
    /**
     * fetch by name
     */
    COMPONENTS_CREATE = "@@componet/COMPONENTS_CREATE",
    COMPONENTS_CREATE_SUCCESS = "@@componet/COMPONENTS_CREATE_SUCCESS",
}