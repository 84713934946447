import { AnyAction, Reducer } from 'redux';
import { ComponentActions } from './actions';

const initialState : any = {}
export const ComponentReducer : Reducer<any> = (state=initialState, action : AnyAction) =>{
    switch (action.type) {
        case ComponentActions.COMPONENTS_FETCH_ALL_SUCCESS:
            return {...state, all : ''};
        default:
            return{...state};
    }
    
}
export default ComponentReducer;