import React, { useState } from "react";
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  useMutation,
} from "@apollo/client";
import gql from "graphql-tag";
import { createUploadLink } from "apollo-upload-client";
import { Bubble } from "../../../ui-ittyni";
import { uploadService } from "src/store/config";

const uploadLink = createUploadLink({ uri: `${uploadService}/graphql` });
const customClient = new ApolloClient({
  link: uploadLink,
  cache: new InMemoryCache(),
});

const UPLOAD_FILE = gql`
  mutation fileUpload($file: FileUploadRequest!) {
    fileUpload(file: $file) {
      filename
      originName
      extension
    }
  }
`;

export function UploadIcon({ uploadedIconData, setUploadedIconData }: any) {
  const [file, setFile] = useState<any>(null);
  const [uploadFile] = useMutation(UPLOAD_FILE, { client: customClient });
  const [isDownloaded, setDownloaded] = useState<boolean>(true);
  const [filesUploaded, setFilesUploaded] = useState<any[]>([]);

  const handleUpload = async (selectedFile: any) => {
    try {
      if (selectedFile) {
        setDownloaded(true);
        const { data } = await uploadFile({
          variables: { file: { file: selectedFile } },
        });

        let tmpfiles = filesUploaded;
        tmpfiles.push(selectedFile);
        setFilesUploaded(tmpfiles);
        let tmpdata = {...data.fileUpload}
        delete tmpdata?.__typename
        setUploadedIconData({ ...tmpdata });
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };
  const handleFileChange = (event: any) => {
    setDownloaded(false);
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleUploadFile = (event: any) => {
    handleUpload(file);
  };

  return (
    <div style={{ width: "90%" }}>
      {file && (
        <div
          style={{ border: "1px dashed gray", width: "200px", height: "250px" }}
        >
          <img
            src={URL.createObjectURL(file)}
            alt={`Preview of ${file?.name}`}
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </div>
      )}
      <div
        style={{
          display: "flex",
          margin: "5px",
          padding: "5px",
          height: "100px",
          border: file ? "none" : "1px dashed gray",
        }}
      >
        <label htmlFor="uploadIcon">
          <Bubble>{file ? "Change Icon" : "upload icon"}</Bubble>
        </label>
        <input
          type="file"
          id="uploadIcon"
          accept="image/png, image/jpeg"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />

        {file && !isDownloaded && (
          <Bubble onClick={handleUploadFile}>valider</Bubble>
        )}
      </div>
    </div>
  );
}
