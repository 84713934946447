import { gql } from "@apollo/client";
import { store } from "../../../index";
import { UsersActions } from "../store/actions";
import { push } from "connected-react-router";

/**
 * fetch users
 */
export const listUsers = () => store.dispatch({
  type: UsersActions.LIST_ALL_USERS,
  payload: { query: `query{users{listAllWithRole{id picture email role{name}}}}` }, path: 'users'
})

/**
 * add new client
 */
export const addNewUser = (userId: string, email: string, role: string, password: string,) => store.dispatch({
  type: UsersActions.USERS_ADD_NEW_CLIENT,
  payload: {
    query: `mutation{users{addNewUser(addedby:"${userId}",email:"${email}",password:"${password}",role:"${role}",status:"active")}}`
  },
  path: 'users'
})

/**
 * link Client to account
 */
export const linkUserToAccount = (userId: string, clientId: string, accountId: string, cabinetId: string) => store.dispatch({
  type: UsersActions.USERS_LINK_CLIENT_TO_ACCOUNT,
  payload: {
    query: `mutation{
      users{
        linkUserToAccount(userId:"${userId}", clientId:"${clientId}", accountId:"${accountId}", cabinetId:"${cabinetId}")
      }
    }`
  },
  path: 'users'
})
/**
 * activate extension in account
 */
export const activateExtensionInAccount = (userId: string, clientId: string, accountId: string, extensions: string[]) => store.dispatch({
  type: UsersActions.USERS_ADD_EXTENSIONS_TO_ACCOUNT,
  payload: {
    query: `mutation{ 
      account{activateModuleInAccount(userId:"${userId}", accountId:"${accountId}", extensions:[
        ${extensions.map(ext => `"${ext}"`)}
      ])}}`
  },
  path: 'users'
})
/**
 * activate extension in account
 */
export const fetchUserDetails = (id: string) => store.dispatch({
  type: UsersActions.USERS_FETCH_USER_DETAILS,
  payload: {
    query: `query{users{getUserDetails(id:"${id}"){id picture email role{name}}}}`
  },
  path: 'users'
})
/**
 * activate extension in account
 */
export const fetchSubscribedAccounts = () => store.dispatch({
  type: UsersActions.USERS_FETCH_SUBSCRIBED_ACCOUNTS,
  payload: {
    query: `query{subscribedAccounts{name}}`
  },
  path: 'users',
  mw: 'subscribedAccounts'
})

// lines to remove after rectification
/**
 * list All laboratories 
 */
export const laboListAll = () =>
    makeRequest(
        '@labo/LAB_LABOS_FETCH_ALL',
        `query{LaboListAll{_id account{name code}contact{tele{fix fax}address{street}}}}`,
        'LaboListAll'
    )
    ;
/**
 * make Request to api
 * with customize params
 */
const makeRequest = (
    action: string,
    payload: string,
    mw: string
) => store.dispatch({
    type: action,
    payload: { query: payload },
    path: 'labos',
    mw: mw
})
/**
 * google oauth2
 */
export const AuthByGoogle = ({ given_name, email, picture, family_name }: any) => store.dispatch({
  type: 'AuthActions.AUTH_BY_GOOGLE',
  path: 'users',
  mw : 'signupWithGoogle',
  payload: {
    query: `mutation{signupWithGoogle(email:"${email}", lname : "${given_name}",fname:"${family_name}", picture:"${picture}")
    {_id token email accounts{labo{_id account{name}} role{name}permissions{component}} picture firstName lastName role{name}}}`
  }
})
/**
 * verify if Logged
 */
// export const isLogged = () => verifyToken(localStorage.getItem('TTUID'))

/**
 * verify token from server
 */
// export const verifyToken = (token: string | null) => {
//   if (token === null) 
//     return store.dispatch({ 
//       type: "@@errors/ERROR_FOUNDED",
//       message: "TOKEN_NOT_FOUND", 
//       code: '10000', 
//       status: 'active', 
//       mw:'tokenNotFounded' 
//     });
//   store.dispatch({
//     type: AuthActions.AUTH_TOKEN_VERIFY,
//     path: 'users',
//     mw : 'verifyFrontToken',
//     payload: { query: `query{verifyFrontToken(token:"${token}"){
//       _id email picture firstName lastName gender dob pob cne inp username
//       role{name}
//       accounts{labo{_id account{name}} space{_id account{name}} role{name}}
//       contact {address city country}
//       tele {value} permissions{component{_id name}}
//     }}` }
//   })
// }

// redirect when logout
export const RedirectToWebSite = (path:string)=>store.dispatch(push('/'+path))