import { Bubble } from "../../../ui-ittyni";
import * as React from "react";
import { Link } from "react-router-dom";

export const ProductSuplier: React.FC<any> = ({ product, setProduct }) => {
  const handleChange = ({ target }: any) =>
    setProduct({ ...product, [target.name]: target.value });
  return (
    <div>
      <div style={{ margin: "10px 0" }}>
        <label>Suplier Name:</label>
        <input
          type="text"
          name="name"
          value={product?.supplier?.name || ""}
          onChange={handleChange}
          style={{ width: "60%" }}
        />
      </div>
      <div style={{ margin: "10px 0", display: "flex" }}>
        <label>supplier Contact:</label>
        <textarea
          name="description"
          value={product?.supplier?.contact || ""}
          onChange={handleChange}
          style={{ width: "60%" }}
        />
      </div>
      <div style={{ margin: "10px 0" }}>
        <label>supplier Email:</label>
        <input
          type="email"
          name="email"
          value={product?.supplier?.name || ""}
          onChange={handleChange}
          style={{ width: "60%" }}
        />
      </div>
    </div>
  );
};
