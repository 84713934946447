import { useLazyQuery } from "@apollo/client";
import {
  AdminUserDashboard,
  INSTALLED_SPACES,
  READ_INSTALLED_ADMIN_COMPONENTS,
  READ_INSTALLED_ADMIN_MODULES,
} from "@core/ittyni_user";
import {
  GlobalStyles,
  WrapperWeb,
  Header,
  WrapperMain,
  WrapperContent,
  WebRightbar,
  WrapperFooter,
} from "@ui";
import AdminSidebar from "@ui/src/layout/sides/AdminSidebar_";
import * as React from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";

/**
 * Get core dashes and create routes
 * in core section
 */
const req = require.context("src/core", true, /\/admin\/.*Dash.*\.tsx?$/);
const reqExt = require.context("src/extensions", true, /\/admin\/.*Dash.*\.tsx?$/);

const modules = req
  .keys()
  .map((key: any) => ({ dash: req(key).default, path: req(key).ROUTE_BASE }));

const extensions = reqExt
  .keys()
  .map((key: any) => ({ dash: reqExt(key).default, path: reqExt(key).ROUTE_BASE }));


export const AdminApp: React.FC<any> = () => {
  const { userConnected, isAuth } = useSelector(({ user }: any) => user);

  // states
  const [coreMenu, setCoreMenu] = React.useState<any>();
  const [footerMenu, setFooterMenu] = React.useState<any>();
  const [extensionMenu, setExtensionMenu] = React.useState<any>();

  // server requests
  const [readAdminExtensions, adminExtensions] = useLazyQuery(
    READ_INSTALLED_ADMIN_COMPONENTS
  );
  const [readAdminModules, adminModules] = useLazyQuery(
    READ_INSTALLED_ADMIN_MODULES
  );

  const [readEnabledSpaces, enabledSpaces] = useLazyQuery(INSTALLED_SPACES);

  if (!userConnected) {
    return <Navigate to="/" replace />;
  }

  React.useEffect(() => {
    if (isAuth) {
      readAdminExtensions();
      readAdminModules();
      readEnabledSpaces();
    }
  }, [isAuth]);

  React.useEffect(() => {
    if (adminExtensions.data?.admin_user_read_installed_admin_components)
      setCoreMenu(
        adminExtensions.data?.admin_user_read_installed_admin_components.map(
          (cm: any) => ({
            icon: cm.icon,
            text: cm.name,
            link: cm.route_base,
          })
        )
      );
  }, [adminExtensions.data]);

  React.useEffect(() => {
    const modules = adminModules.data?.admin_user_read_installedAdminModules
    if (modules)
      setExtensionMenu(
        modules.map(
          (cm: any) => ({
            icon: cm.icon,
            text: cm.name,
            link: cm.route_base,
          })
        )
      );
  }, [adminModules.data]);

  React.useEffect(() => {
    const spaces = enabledSpaces.data?.admin_user_read_installedSpaces;
    if (spaces) {
      setFooterMenu(
        spaces.map((sp: any) => ({
          icon: <i className="fas fa-building" />,
          text: sp.name,
          link: `/space/khmammed/${sp._id}/`,
        }))
      );
    }
  }, [enabledSpaces.data]);

  React.useEffect(() => {localStorage.removeItem("space")}, []);
  return (
    <>
      <GlobalStyles />
      <WrapperWeb>
        <Header user={userConnected} />
        <AdminSidebar
          user={{
            username: userConnected?.email?.split("@")[0],
            role: "admin",
            picture: userConnected.picture,
          }}
          coreMenuItems={coreMenu}
          extensionsMenuItems={extensionMenu}
          footerMenuItems={footerMenu}
        />
        <WrapperMain>
          <WrapperContent>
            <Routes>
              {modules.map((module: any) => (
                <Route
                  key={module.path}
                  path={module.path + "/*"}
                  element={<module.dash />}
                />
              ))}
              {extensions.map((module: any) => (
                <Route
                  key={module.path}
                  path={module.path + "/*"}
                  element={<module.dash />}
                />
              ))}
            </Routes>
          </WrapperContent>
          <WebRightbar>this right bar</WebRightbar>
        </WrapperMain>
        <WrapperFooter>footer</WrapperFooter>
      </WrapperWeb>
    </>
  );
};
