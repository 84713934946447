import * as React from "react";
import styled from "styled-components";
import { Input, TextArea, Bubble } from "@ui";
import { UploadIcon } from "./upload_icon";
import { useMutation, useQuery } from "@apollo/client";
import { READ_CATEGORIES, WRITE_CATEGORY } from "../controller/categoryController";

export const AdminAddCategory: React.FC<any> = () => {
    const [category, setCategory] = React.useState<any>({
        name: '',
        description: '',
        parentsCategory: [],
    });
    const [icon, setIcon] = React.useState<any>(null);
    const [dropdownVisible, setDropdownVisible] = React.useState<boolean>(false);
    const [selectedCategories, setSelectedCategories] = React.useState<any[]>([]);

    const { data: categoriesData, refetch: categoriesRefetch } = useQuery(READ_CATEGORIES);

    const [saveCategory, { loading: loadingCategoryData }] = useMutation(WRITE_CATEGORY, {
        context: { clientName: 'category' }
    });

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setCategory({ ...category, [event.target.name]: event.target.value });
    };

    const handleSaveCategory = () => {
        const categoryData = { ...category };

        // Remove parentsCategory if it's empty
        if (categoryData.parentsCategory.length === 0) {
            delete categoryData.parentsCategory;
        }

        saveCategory({ variables: { category: categoryData, icon } });
    };

    const handleCategoryFocus = () => {
        categoriesRefetch();
        setDropdownVisible(true);
    };

    const handleCategorySelect = (selectedCategory: any) => {
        const updatedSelectedCategories = [...selectedCategories, selectedCategory];

        // Update selected categories and parentsCategory ids
        setSelectedCategories(updatedSelectedCategories);
        setCategory({
            ...category,
            parentsCategory: updatedSelectedCategories.map(cat => cat._id)
        });

        setDropdownVisible(false);
    };
    console.log(category)
    return (
        <FormContainer>
            <FormRow>
                <StyledInput
                    name="parentsCategory"
                    type="text"
                    placeholder="Add category parent"
                    onFocus={handleCategoryFocus}
                />
                {dropdownVisible && (
                    <Dropdown>
                        {categoriesData?.admin_category_read_categories.map((cat: any) => (
                            <DropdownItem key={cat._id} onClick={() => handleCategorySelect(cat)}>
                                {cat.name}
                            </DropdownItem>
                        ))}
                    </Dropdown>
                )}
            </FormRow>
            <FormRow style={{ flexDirection: "row" }}>
                {selectedCategories.map((cat: any) => (
                    <Bubble key={cat._id}>{cat.name}</Bubble>
                ))}
            </FormRow>
            <FormRow>
                <StyledInput
                    name="name"
                    type="text"
                    placeholder="Add category title"
                    value={category.name}
                    onChange={handleInputChange}
                />
            </FormRow>
            <FormRow>
                <StyledTextarea
                    name="description"
                    placeholder="Category description..."
                    value={category.description}
                    onChange={handleInputChange}
                />
            </FormRow>
            <ImageUploaderContainer>
                <UploadIcon uploadedIconData={icon} setUploadedIconData={setIcon} />
            </ImageUploaderContainer>
            <FormRow>
                <SaveButton onClick={handleSaveCategory}>
                    {loadingCategoryData ? "Saving..." : "Save Category"}
                </SaveButton>
            </FormRow>
        </FormContainer>
    );
};

const FormContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  width: 100%;
`;

const FormRow = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledTextarea = styled(TextArea)`
  padding: 10px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #ccc;
  min-height: 100px;
  resize: none;
`;

const StyledInput = styled(Input)`
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
`;

const ImageUploaderContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-top: 20px;
  width: 100%;
`;

const SaveButton = styled(Bubble)`
  align-self: flex-start;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const Dropdown = styled('div')`
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
`;

const DropdownItem = styled('div')`
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: #f1f1f1;
  }
`;
