// ProductCard.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Card = styled("div")`
  width: 250px;
  border: 2px solid #000000;
  margin: 0 5px 5px;
  overflow: hidden;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const ImageContainer = styled("div")`
  position: relative;
`;

const ProductImage = styled("img")`
  width: 200px;
  height: 250px;
`;

const ProductInfo = styled("div")`
  padding: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Custom box-shadow */
`;

const ProductName = styled("div")`
  font-size: 18px;
  font-weight: bold;
  margin: 5px 0;
  height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limits text to 3 lines */
  -webkit-box-orient: vertical;
  white-space: normal; /* Ensures text wraps within the container */
`;

const ProductPrice = styled("div")`
  font-size: 20px;
  color: #ff5722; /* Bright color to make it stand out */
  font-weight: bold;
  margin-top: 10px;
  padding: 5px;
  background-color: #fffbe6; /* Light background to create contrast */
  border-radius: 5px; /* Rounded edges for a modern look */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15); /* Subtle shadow to lift it off the background */
`;

const NavigatorButton = styled("button")<{ left?: boolean }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  padding: 5px;
  cursor: pointer;
  ${(props) => (props.left ? "left: 10px;" : "right: 10px;")}
`;

const ProductRank = styled("div")`
  display: flex;
  align-items: center;
  margin-top: 5px;
`;

const Star = styled("i")<{ active: boolean }>`
  color: ${(props) =>
    props.active
      ? "#FFD700"
      : "#ccc"}; /* Yellow for active stars, grey for inactive */
  margin-right: 2px;
`;

const SalesCount = styled("div")`
  font-size: 16px;
  color: #48814a; /* A soothing green color to indicate success or high sales */
  font-weight: bold;
  margin-top: 8px;
  padding: 4px 8px;
  background-color: #e8f5e9; /* Light green background to match the text color */
  border-radius: 12px; /* Rounded edges for a modern pill-shaped look */
  display: inline-block; /* Ensures the padding and border-radius are applied correctly */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow to lift it slightly */
`;

const RatingCount = styled("span")`
  font-size: 14px;
  color: #777;
  margin-left: 8px; /* Space between the stars and the rating count */
`;


const ProductCard = ({ images, name, price, rank, ratingCount }: any) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <Card>
      <ImageContainer>
        <ProductImage src={images[currentImageIndex]} alt={name} />
        <NavigatorButton left onClick={handlePrevImage}>
          ❮
        </NavigatorButton>
        <NavigatorButton onClick={handleNextImage}>❯</NavigatorButton>
      </ImageContainer>
      <ProductInfo>
        <ProductName><Link to={"1"}>{name}</Link></ProductName>
        <ProductRank>
          {[...Array(5)].map((_, i) => (
            <Star key={i} className="fas fa-star" active={i < rank} />
          ))}
          <RatingCount>({ratingCount})</RatingCount>
        </ProductRank>
        <SalesCount>Sales: {100}</SalesCount>
        <ProductPrice>{price}</ProductPrice>
      </ProductInfo>
    </Card>
  );
};

export default ProductCard;
