import React from 'react';
import styled from 'styled-components';

const Container = styled("div")`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  font-family: Arial, sans-serif;
`;

const Column = styled("div")<{ primary?: boolean }>`
  flex: ${({ primary }) => (primary ? 2 : 1)};
  margin: 10px;
`;

const ShippingAddress = styled("div")`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
`;

const ChangeButton = styled("button")`
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9em;

  &:hover {
    background-color: #0056b3;
  }
`;

const AddressParagraph = styled("p")<{ isPrimary?: boolean }>`
  margin: 5px 0;
  font-weight: ${({ isPrimary }) => (isPrimary ? "bold" : "normal")};
  color: ${({ isPrimary }) => (isPrimary ? "#333" : "#666")};
`;

const PaymentMethods = styled("div")`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const OrderSummary = styled("div")`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const SummaryTitle = styled("h3")`
  margin-bottom: 20px;
  font-size: 1.2em;
  color: #333;
`;

const SummaryItem = styled("div")`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  color: #666;
`;

const Total = styled("div")`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  font-weight: bold;
  color: #333;
`;

const PlaceOrderButton = styled("button")`
  width: 100%;
  padding: 15px;
  background-color: #ff4747;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  margin-top: 20px;
  cursor: pointer;

  &:hover {
    background-color: #ff2e2e;
  }
`;

const ProductItem = styled("div")`
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const ProductImage = styled("img")`
  width: 80px;
  height: auto;
  margin-right: 20px;
`;

const ProductInfo = styled("div")`
  flex: 1;
`;

const ProductPrice = styled("div")`
  font-weight: bold;
  color: #333;
`;

const ProductQuantity = styled("div")`
  display: flex;
  align-items: center;
`;

const QuantityButton = styled("button")`
  padding: 5px;
  background-color: #eee;
  border: none;
  border-radius: 3px;
  margin: 0 5px;
  cursor: pointer;
`;


const OrderCheckout = () => {
  return (
    <Container>
      <Column primary>
        <ShippingAddress>
          <h3>Shipping address</h3>
          <ChangeButton>Change Address</ChangeButton>
          <AddressParagraph isPrimary>khmalech mohammed +212 708239690</AddressParagraph>
          <AddressParagraph>Rue 7 N 4 route sidi hrazem / narjiss</AddressParagraph>
          <AddressParagraph>FES, Fes - Meknes, Morocco, 30000</AddressParagraph>
        </ShippingAddress>
        <PaymentMethods>
          <h3>Payment Methods</h3>
          <p>Add a new card</p>
          {/* Add payment method buttons or icons here */}
        </PaymentMethods>
        <ProductItem>
          <ProductImage src="product-image-url.jpg" alt="Product" />
          <ProductInfo>
            <p>
              HDTV To VGA Line With Audio HDTV Compatible Converter 1080P HDMI
              Female Input To VGA Male Adapter
            </p>
            <ProductPrice>US $1.00</ProductPrice>
            <ProductQuantity>
              <QuantityButton>-</QuantityButton>
              <span>10</span>
              <QuantityButton>+</QuantityButton>
            </ProductQuantity>
          </ProductInfo>
        </ProductItem>
      </Column>
      <Column>
        <OrderSummary>
          <SummaryTitle>Summary</SummaryTitle>
          <SummaryItem>
            <span>Subtotal</span>
            <span>US $10.00</span>
          </SummaryItem>
          <SummaryItem>
            <span>Saved</span>
            <span>-US $0.09</span>
          </SummaryItem>
          <SummaryItem>
            <span>Shipping fee</span>
            <span>US $9.28</span>
          </SummaryItem>
          <Total>
            <span>Total</span>
            <span>US $19.19</span>
          </Total>
          <PlaceOrderButton>Place order</PlaceOrderButton>
        </OrderSummary>
      </Column>
    </Container>
  );
};

export default OrderCheckout;
