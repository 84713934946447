import * as React from 'react'
import {createRoot} from 'react-dom/client'
import Main from './main'
import { createBrowserHistory } from 'history';
import configureStore from './configureStore';

const history = createBrowserHistory();

const initialState = window.INITIAL_REDUX_STATE;

export const store = configureStore(history, initialState);

const rootElement: any = document.getElementById("root");

const root = createRoot(rootElement)


root.render(<Main store={store} history={history} />);
