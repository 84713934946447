import React from 'react';
import styled from 'styled-components';

const QuantitySection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
`;

const QuantityText = styled.div`
  font-size: 14px;
`;

const QuantityControls = styled.div`
  display: flex;
  align-items: center;
`;

const QuantityButton = styled.button`
  width: 25px;
  height: 25px;
  background-color: #eee;
  border: 1px solid #ddd;
  border-radius: 50%;
  font-size: 18px;
  line-height: 0;
  cursor: pointer;
  outline: none;
`;

const QuantityDisplay = styled.div`
  font-size: 14px;
  margin: 0 10px;
`;

const MaxQuantity = styled.div`
  font-size: 14px;
`;

const Quantity = () => (
  <QuantitySection>
    <QuantityText>Quantity</QuantityText>
    <QuantityControls>
      <QuantityButton>-</QuantityButton>
      <QuantityDisplay>1</QuantityDisplay>
      <QuantityButton>+</QuantityButton>
    </QuantityControls>
    <MaxQuantity>Max. 1 pcs/shopper</MaxQuantity>
  </QuantitySection>
);

export default Quantity;