// src/productComponent/ThumbnailSidebar.tsx
import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import ProductImageUploader from './ProductImageUploader';

const SidebarContainer = styled("div")`
  width: 10%;
  height: 100%;
  position: relative;
`;

const ThumbnailWrapper = styled("div")`
  height: calc(100% - 80px);
  overflow-y: scroll;
  padding: 10px 0;
  box-sizing: border-box;

  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  scrollbar-width: none;
  -ms-overflow-style: none;
`;

const ThumbnailContainer = styled("div")`
  position: relative;
  margin: 10px 0;
`;

const Thumbnail = styled("img")<any>`
  width: 80%;
  height: 80px;
  cursor: pointer;
  object-fit: cover;
  border: ${(props) =>
    props.isDefault
      ? '2px solid red'
      : props.active
      ? '2px solid #007bff'
      : 'none'};
`;


const CloseIcon = styled("i")<any>`
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 20px;
  color: #ff0000;
  cursor: pointer;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  transition: opacity 0.3s ease;
`;

interface ThumbnailSidebarProps {
  currentIndex: number;
  defaultImageIndex: number;
  onThumbnailClick: (index: number) => () => void;
  onAddThumbnailClick: () => void;
  onCancel: () => void;
  onDeleteThumbnail: (index: number) => void;
  images: string[]; // Add this prop to accept images
  onFilesSelected: (files: string[]) => void; // Callback to handle file selection
}

const ThumbnailSidebar: React.FC<ThumbnailSidebarProps> = ({
  currentIndex,
  defaultImageIndex,
  onThumbnailClick,
  onAddThumbnailClick,
  onDeleteThumbnail,
  images,
  onFilesSelected,
  onCancel,
}) => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const thumbnailRefs = useRef<(HTMLImageElement | null)[]>([]);

  const handleFilesSelected = (files: File[]) => {
    const newImageURLs = files.map((file) => URL.createObjectURL(file));
    onFilesSelected(newImageURLs);
  };

  useEffect(() => {
    if (thumbnailRefs.current[currentIndex]) {
      thumbnailRefs.current[currentIndex]?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [currentIndex]);

  return (
    <SidebarContainer>
      <ThumbnailWrapper>
        {images.map((src, index) => (
          <ThumbnailContainer
            key={index}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            <Thumbnail
              src={src}
              alt={`Thumbnail ${index + 1}`}
              onClick={onThumbnailClick(index)}
              active={currentIndex === index}
              isDefault={index === defaultImageIndex}
              ref={(el: any) => (thumbnailRefs.current[index] = el)}
            />
            <CloseIcon
              className="fa fa-times"
              visible={hoveredIndex === index}
              onClick={() => onDeleteThumbnail(index)}
            />
          </ThumbnailContainer>
        ))}
      </ThumbnailWrapper>
      <ProductImageUploader
        onFilesSelected={handleFilesSelected}
        onCancel={onCancel} 
        onConfirm={function (): void {
          throw new Error('Function not implemented.');
        } }      />
    </SidebarContainer>
  );
};

export default ThumbnailSidebar;