import * as React from "react";
import ProductDetailsPage from "./ProductDetails.v2";
import ProductCard from "./ProductCards/ProductCard";
import { Link, Route, Routes } from "react-router-dom";
import ProductFilters from "./ProductFilters/ProductFilters";
import OrderCheckout from "./Order/OrderCheckout";
import ShoppingCart from "./Order/OrderCart";

export const ROUTE_BASE = "products";

const WebProductDashboard: React.FC<any> = () => {
  return (
    <div style={{ width: "100%" }}>
      <Routes>
        <Route
          path=""
          element={
            <div style={{display: "flex"}}>
              <ProductFilters />
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <ProductCard
                  images={[
                    "https://media.licdn.com/dms/image/D5612AQFhEWBnE6VGBA/article-cover_image-shrink_600_2000/0/1708095924573?e=2147483647&v=beta&t=Voe7__kG0dqo8SmZqlW9KmmAdkSJKTFl6M-FONskyiM",
                    "https://www.pngall.com/wp-content/uploads/15/Car-Key-PNG-Images-HD.png",
                    "https://media.ed.edmunds-media.com/non-make/carcare/carcare_315114_717.jpg",
                  ]}
                  name={
                    "Xhorse VVDI BE Car Smart Remote key For Mercedes Benz 315/434mhz W211 W212 W203 W204 W205 W210 W221 W222 3/4 Buttons shell case"
                  }
                  price={"19 MAD"}
                  rank={4}
                  sales={150}
                  ratingCount={100}
                />
                <ProductCard
                  images={[
                    "https://media.licdn.com/dms/image/D5612AQFhEWBnE6VGBA/article-cover_image-shrink_600_2000/0/1708095924573?e=2147483647&v=beta&t=Voe7__kG0dqo8SmZqlW9KmmAdkSJKTFl6M-FONskyiM",
                    "https://www.pngall.com/wp-content/uploads/15/Car-Key-PNG-Images-HD.png",
                    "https://media.ed.edmunds-media.com/non-make/carcare/carcare_315114_717.jpg",
                  ]}
                  name={"key car"}
                  price={"100 MAD"}
                  rank={2}
                  sales={20}
                  ratingCount={10}
                />
                <ProductCard
                  images={[
                    "https://media.licdn.com/dms/image/D5612AQFhEWBnE6VGBA/article-cover_image-shrink_600_2000/0/1708095924573?e=2147483647&v=beta&t=Voe7__kG0dqo8SmZqlW9KmmAdkSJKTFl6M-FONskyiM",
                    "https://www.pngall.com/wp-content/uploads/15/Car-Key-PNG-Images-HD.png",
                    "https://media.ed.edmunds-media.com/non-make/carcare/carcare_315114_717.jpg",
                  ]}
                  name={"key car"}
                  price={"19 MAD"}
                  rank={4}
                  sales={150}
                  ratingCount={100}
                />
                <ProductCard
                  images={[
                    "https://media.licdn.com/dms/image/D5612AQFhEWBnE6VGBA/article-cover_image-shrink_600_2000/0/1708095924573?e=2147483647&v=beta&t=Voe7__kG0dqo8SmZqlW9KmmAdkSJKTFl6M-FONskyiM",
                    "https://www.pngall.com/wp-content/uploads/15/Car-Key-PNG-Images-HD.png",
                    "https://media.ed.edmunds-media.com/non-make/carcare/carcare_315114_717.jpg",
                  ]}
                  name={"key car"}
                  price={"19 MAD"}
                  rank={4}
                  sales={150}
                  ratingCount={100}
                />
                <ProductCard
                  images={[
                    "https://media.licdn.com/dms/image/D5612AQFhEWBnE6VGBA/article-cover_image-shrink_600_2000/0/1708095924573?e=2147483647&v=beta&t=Voe7__kG0dqo8SmZqlW9KmmAdkSJKTFl6M-FONskyiM",
                    "https://www.pngall.com/wp-content/uploads/15/Car-Key-PNG-Images-HD.png",
                    "https://media.ed.edmunds-media.com/non-make/carcare/carcare_315114_717.jpg",
                  ]}
                  name={"key car"}
                  price={"19 MAD"}
                  rank={4}
                  sales={150}
                  ratingCount={100}
                />
                <ProductCard
                  images={[
                    "https://media.licdn.com/dms/image/D5612AQFhEWBnE6VGBA/article-cover_image-shrink_600_2000/0/1708095924573?e=2147483647&v=beta&t=Voe7__kG0dqo8SmZqlW9KmmAdkSJKTFl6M-FONskyiM",
                    "https://www.pngall.com/wp-content/uploads/15/Car-Key-PNG-Images-HD.png",
                    "https://media.ed.edmunds-media.com/non-make/carcare/carcare_315114_717.jpg",
                  ]}
                  name={"key car"}
                  price={"19 MAD"}
                  rank={4}
                  sales={150}
                  ratingCount={100}
                />
                <ProductCard
                  images={[
                    "https://media.licdn.com/dms/image/D5612AQFhEWBnE6VGBA/article-cover_image-shrink_600_2000/0/1708095924573?e=2147483647&v=beta&t=Voe7__kG0dqo8SmZqlW9KmmAdkSJKTFl6M-FONskyiM",
                    "https://www.pngall.com/wp-content/uploads/15/Car-Key-PNG-Images-HD.png",
                    "https://media.ed.edmunds-media.com/non-make/carcare/carcare_315114_717.jpg",
                  ]}
                  name={"key car"}
                  price={"19 MAD"}
                  rank={4}
                  sales={150}
                  ratingCount={100}
                />
              </div>
            </div>
          }
        />
        <Route path="1" element={<ProductDetailsPage />} />
        <Route path="cart" element={<ShoppingCart />} />
        <Route path="orderCheckout" element={<OrderCheckout />} />
      </Routes>
    </div>
  );
};

export default WebProductDashboard;