import React from 'react';
import styled from 'styled-components';

const CommitmentSection = styled.div`
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
`;

const Commitment = () => (
  <CommitmentSection>iTTyni commitment</CommitmentSection>
);

export default Commitment;
