import { useQuery, useLazyQuery } from "@apollo/client";
import * as React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { READ_CATEGORIES, READ_CATEGORY_DETAILS, READ_SPACE_CATEGORIES, SPACE_READ_CATEGORY_DETAILS } from "../controller/categoryController";
const uploadServer = "http://localhost:3001";

const Container = styled('div')`
  padding: 20px;
`;

const Title = styled('h1')`
  margin-bottom: 20px;
`;

const StyledLink = styled(Link)`
  display: inline-block;
  margin-bottom: 20px;
  color: #007bff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const CategoryList = styled('ul')`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
`;

const CategoryItem = styled('li')`
  box-shadow: 0 0 5px 0 #888888;
  border-radius: 30px;
  padding: 16px;
  width: 120px;
  margin: 10px;
  cursor: pointer;
`;

const CategoryIcon = styled('div')`
  background-size: contain;
  background-repeat: no-repeat;
  height: 150px;
`;

const CategoryName = styled('div')`
  font-size: 21px;
  font-weight: bold;
  font-family: cursive;
`;

const CategoryDescription = styled('div')`
  margin-top: 10px;
`;

const PlusIcon = styled('div')`
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const AddText = styled('div')`
  font-size: 14px;
  color: #888;
`;

const SubcategoryList = styled('ul')`
  list-style: none;
  padding: 0;
  margin-top: 20px;
`;

const SubcategoryItem = styled('li')`
  margin: 10px 0;
  font-size: 18px;
  font-weight: bold;
`;

export const SpaceCategoriesList: React.FC<any> = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const _id = queryParams.get('_id');

  const {
    data: categoriesData,
    loading: categoriesDataLoading,
    refetch: categoriesRefetch,
  } = useQuery(READ_SPACE_CATEGORIES);

  const [categoryDetails, { data: categoryDetailsData }] = useLazyQuery(SPACE_READ_CATEGORY_DETAILS);

  React.useEffect(() => {
    categoriesRefetch();
  }, [categoriesRefetch]);
console.log(_id)
  React.useEffect(() => {
    
    if (_id) {
      categoryDetails({ variables: { _id } });
    }
  }, [_id, categoryDetails]);

  const handleCategoryClick = (categoryId: string) => {
    navigate(`../Category_List?_id=${categoryId}`);
  };

  return (
    <Container>
      <Title>Category Manager</Title>
      {_id && categoryDetailsData ? (
        <div>
          <StyledLink to="/admin/khmammed/category/Category_List">Back to All Categories</StyledLink>
          <CategoryItem>
            <CategoryIcon
              style={{
                backgroundImage: `url("${uploadServer}/images/${categoryDetailsData.space_category_read_categoryDetails?.icon?.filename}")`,
              }}
            />
            <CategoryName>{categoryDetailsData.space_category_read_categoryDetails?.name}</CategoryName>
            <CategoryDescription>{categoryDetailsData.space_category_read_categoryDetails.description}</CategoryDescription>
          </CategoryItem>
          <Title>SubCategories</Title>
          <CategoryList>
            {categoryDetailsData.space_category_read_categoryDetails?.subcategories.map((subcategory: any, index: number) => (
              <CategoryItem
                key={subcategory?._id}
                onClick={() => handleCategoryClick(subcategory?._id)}
              >
                <CategoryIcon
                  style={{
                    backgroundImage: `url("${uploadServer}/images/${subcategory.icon?.filename}${subcategory.icon?.extension}")`,
                  }}
                />
                <CategoryName>{subcategory?.name}</CategoryName>
                <CategoryDescription>{subcategory?.description}</CategoryDescription>
              </CategoryItem>
            ))}
          </CategoryList>
        </div>
      ) : (
        <CategoryList>
          <CategoryItem onClick={() => navigate('../CreateCategory')}>
            <PlusIcon>+</PlusIcon>
            <AddText>Click to add new Category</AddText>
          </CategoryItem>
          {categoriesData?.space_category_read_categories.map((category: any) => (
            <CategoryItem
              key={category._id}
              onClick={() => handleCategoryClick(category._id)}
            >
              <CategoryIcon
                style={{
                  backgroundImage: `url("${uploadServer}/images/${category.icon?.filename}${category.icon?.extension}")`,
                }}
              />
              <CategoryName>{category.name}</CategoryName>
              <CategoryDescription>{category.description}</CategoryDescription>
            </CategoryItem>
          ))}
        </CategoryList>
      )}
    </Container>
  );
}
