import { gql } from "@apollo/client";

/**
 * queries
 */
export const READ_SPACES = gql`
  query admin_space_read_spaces {
    admin_space_read_spaces {
      _id
      name
      photos
    }
  }
`;
export const READ_SPACE_EXTENSIONS = gql`
  query read_SpaceExtensions($_id: ID!) {
    read_spaceExtensions(_id: $_id) {
      _id
      name
      status
      description
    }
  }
`;

/**
 * mutations
 */
export const WRITE_ACTIVE_COMPONENTS = gql`
  mutation Write_ActiveComponents($_id: ID!, $componentId: ID!) {
    write_activateExtensionOnSpace(_id: $_id, componentId: $componentId) {
      _id
      name
    }
  }
`;

export const WRITE_SPACE = gql`
  mutation admin_space_write_spaces($space: SpaceInput) {
    admin_space_write_spaces(space: $space) {
      name
    }
  }
`;
export const ENABLE_SPACE = gql`
  mutation admin_space_write_enableSpace($_id: ID!) {
    admin_space_write_enableSpace(_id: $_id)
  }
`;
export const INSTALLED_COMPONENTS = gql`
  query space_space_read_installedComponents {
    space_space_read_installedComponents {
      enabled {
        _id
        name
        route_base
        icon
        status
        version
      }
      installed {
        _id
        name
        route_base
        icon
        status
        version
      }
    }
  }
`;
export const ENABLE_SPACE_EXTENSION = gql`
  mutation space_space_write_enableExtension($_id: ID!) {
    space_space_write_enableExtension(_id: $_id)
  }
`;
export const SPACE_ENABLE_CATEGORY = gql`
  mutation space_space_write_enableCategories($_ids: [ID!]) {
    space_space_write_enableCategories(_ids: $_ids)
  }
`;
export const SPACE_READ_ENABLED_CATEGORIES = gql`
  query space_space_read_enabledCategories($_ids: [ID!]) {
    space_space_read_enabledCategories(_ids: $_ids) {
      _id
      name
      parentsCategory
      icon {
        filename
      }
    }
  }
`;

export const SPACE_ACTIVATED_CATEGORIES = gql`
  query space_space_read_activatedCategories {
    space_space_read_activatedCategories {
      _id
      name
      description
      icon {
        filename
      }
      parentsCategory
    }
}`;
