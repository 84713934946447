import { ComponentActions } from "..";
import { store } from "../../..";
import { gql } from "@apollo/client";

export const createCompoent = (
  name: string,
  ico: string,
  description: string,
  space: string
) =>
  store.dispatch({
    type: ComponentActions.COMPONENTS_CREATE,
    payload: {
      query: `mutation{createComponent(name:"${name}", ico:"${ico}", description:"${description}", space:"${space}")}`,
    },
    path: "component",
    mw: "createComponent",
  });

export const WRITE_COMPONENT = gql`
  mutation CREATE_COMPONENT(
    $extension: _Extension
  ) {
    createComponent(extension: $extension)
  }
`;

export const READ_ACTIVE_COMPONENTS_SPACE = gql`
  query Read_ActiveComponentsSpace($space: String) {
    readActiveExtensionsBySpace(space: $space) {
      _id
      name
      description
    }
  }
`;
