import { useEffect } from 'react';
import { googleSignInScritpURL } from '../constants';
import useScript from './useScripts';

declare global {
    interface Window {
        google: any
        [key: string]: any
    }
}

interface IdConfiguration {
    client_id : string
    auto_select? : boolean
    callback? : (response: CredentialResponse) => any;
    native_callback? : (response: CredentialResponse) => any;
    cancel_on_tap_outside?: boolean
    prompt_parent_id?: string
    none?: string
    context?: string
    state_cookie_domain?: string
    allowed_parent_origin?: string
    intermediate_iframe_close_callback?: string
}

interface UseGoogleTapLoginProps extends IdConfiguration {
    disabled?: boolean
}

interface CredentialResponse {
    credential: string
    select_by: string
    client_id: string
    data?: any
}

export const useGoogleTapLogin = (token: string | null, configuration: UseGoogleTapLoginProps)=>{
    const script = useScript(googleSignInScritpURL)

    const listener = useEffect(
        ()=>{
            if (!token && script === 'ready' && !configuration.disabled){
                window.google.accounts.id.initialize({...configuration});
                window.google.accounts.id.prompt();
            }
        }, [configuration, script]
    )

    return () => listener;
}