import { all, fork, takeEvery } from 'redux-saga/effects'
import * as config from '../../../store/config';
import { ComponentActions } from './actions';
import { AnyAction } from 'redux';

//watcher func dispatcher
function* watchComponent() {
    // dispatch fetch all existing components
    yield takeEvery(ComponentActions.COMPONENTS_FETCH_ALL,
        (action: AnyAction) => config.tryFetching(
            action,
            ComponentActions.COMPONENTS_FETCH_ALL_SUCCESS
        ))
    // fetch component by id
    yield takeEvery(ComponentActions.COMPONENTS_FETCH_BY_ID,
        (action: AnyAction) => config.tryFetching(
            action,
            ComponentActions.COMPONENTS_FETCH_BY_ID_SUCCESS
        ))
    // fetch component by name
    yield takeEvery(ComponentActions.COMPONENTS_FETCH_BY_NAME,
        (action: AnyAction) => config.tryFetching(
            action,
            ComponentActions.COMPONENTS_FETCH_BY_NAME_SUCCESS
        ))
    // create new component
    yield takeEvery(ComponentActions.COMPONENTS_CREATE,
        (action: AnyAction) => config.tryFetching(
            action,
            ComponentActions.COMPONENTS_CREATE_SUCCESS
        ))
}

export function* ComponentSagas() {
    yield all([fork(watchComponent)])
}

export default ComponentSagas;