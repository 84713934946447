import * as React from "react";
import MarkdownEditor from "@uiw/react-markdown-editor";
import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_COMPONENT,
  READ_COMPONENT,
  UPDATE_COMPONENT,
} from "../controller/moduleController";
import svgToString from "@utils/svgToString";
import { useParams } from "react-router-dom";
import { IcoRenderer, Input } from "@ui";

interface Extension {
  name?: string;
  description?: string;
  version?: string;
  space?: string;
  icon?: string;
  iconRaw?: string;
  route_base?: string;
  moduleType?: "core" | "user";
}

const AdminUpdateExtension: React.FC = () => {
  const [extension, setExtension] = React.useState<any>({});
  const [iconRaw, setIconRaw] = React.useState<string | null>(null);
  const [oldExt, setOldExt] = React.useState<Extension | null>(null);

  const [updateModule, updateModuleData] = useMutation(UPDATE_COMPONENT);

  const { _id } = useParams();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setExtension((prev: any) => ({ ...prev, [name]: value }));
  };

  const { data: componentDetails, loading: componentLoading } = useQuery(
    READ_COMPONENT,
    { variables: { _id } }
  );

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target?.result as string;
        const formattedSvg: any = svgToString(text);
        if (formattedSvg) {
          setIconRaw(formattedSvg);
          setExtension((prev: any) => ({
            ...prev,
            icon: [{ icon_value: formattedSvg, icon_context: "admin" }],
          }));
        }
      };
      reader.readAsText(file);
    }
  };

  const handleModuleTypeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setExtension((prev: any) => ({
      ...prev,
      moduleType: event.target.value as "core" | "user",
    }));
  };

  const handleUpdateComponent = () => {
    updateModule({ variables: { _id, input: extension } });
    // Optionally, handle the data submission to the server here
  };

  const getInputValue = (key: keyof Extension) => {
    return extension[key] !== undefined ? extension[key] : oldExt?.[key] || '';
  };

  React.useEffect(() => {
    if (componentDetails) {
      let { __typename, ...component } =
        componentDetails.admin_components_read_component;
      setOldExt(component);
    }
  }, [componentDetails]);

  return (
    <div style={{ width: "inherit" }}>
      <h1>Add Extension</h1>
      <div>
        <div>
          <label htmlFor="name">Name: </label>
          <input
            id="name"
            name="name"
            value={getInputValue("name")}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label htmlFor="route_base">Route Base: </label>
          <Input
            id="route_base"
            name="route_base"
            value={oldExt?.route_base || ''}
            onChange={handleInputChange}
            disabled
          />
        </div>
        <div>
          <label htmlFor="icon">Icon: </label>
          <input
            id="icon"
            type="file"
            accept="image/svg+xml"
            onChange={handleFileChange}
          />
        </div>
        {iconRaw && (
          <div>
            <label>Preview: </label>
            <div
              dangerouslySetInnerHTML={{ __html: iconRaw }}
              style={{
                border: "1px solid #ccc",
                padding: "5px",
                width: "50px",
                height: "50px",
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            />
          </div>
        )}
        {!iconRaw && oldExt?.icon && (
          <div
            style={{
              border: "1px solid #ccc",
              padding: "5px",
              width: "50px",
              height: "50px",
              overflow: "hidden",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IcoRenderer
              svgString={oldExt.icon}
              height="35px"
              width="35px"
            />
          </div>
        )}
        <div>
          <label htmlFor="version">Version: </label>
          <input
            id="version"
            name="version"
            value={getInputValue('version')}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div>
        <label htmlFor="description">Description: </label>
        <MarkdownEditor
          height="400px"
          value={getInputValue("description")}
          onChange={(value) =>
            setExtension({ ...extension, description: value })
          }
        />
      </div>
      <div>
        <button onClick={handleUpdateComponent}>Update Component</button>
      </div>
    </div>
  );
};

export default AdminUpdateExtension;
